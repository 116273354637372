/**
 * Created by osirvent on 30/06/2016.
 */
angular
    .module('annexaApp')
    .controller('ArchiveController', ['$scope', '$rootScope', '$filter', 'HeaderService', 'RestService', 'Language', '$stateParams', 'RouteStateFactory', '$state', 'GlobalDataFactory', 'DialogsFactory', 
    function ($scope, $rootScope, $filter, HeaderService, RestService, Language, $stateParams, RouteStateFactory, $state, GlobalDataFactory, DialogsFactory) {
    	HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.archive.finally' || message.state.name == 'annexa.archive.close' || message.state.name == 'annexa.archive.transfer' || message.state.name == 'annexa.archive.rejected' || message.state.name == 'annexa.archive.archive') {
            	$rootScope.headButtons = [];
            	$rootScope.subHeadButtons = [];
            	if(message.state.name == 'annexa.archive.finally'){
            		$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#archiveFinally-list', 'fa fa-download', 'global.tram.literals.export_dossier', undefined, 'exportDossier'));
            		$rootScope.subHeadButtons.push(new HeadButtonSearch('#tableFilter'));
	            	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#archiveFinally-list','fa-plus fa-fw','global.archive.finally.closeSelecteds',undefined,'closeSelectedsDossiers'));
					$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#archiveFinally-list', 'fa fa-download', 'global.literals.export', undefined, 'exportListFinally'));            		
            	}else if(message.state.name == 'annexa.archive.close'){
            		$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#archiveClose-list', 'fa fa-download', 'global.tram.literals.export_dossier', undefined, 'exportDossier'));
                	$rootScope.subHeadButtons.push(new HeadButtonSearch('#tableFilter'));
	            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
	            		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
	            			$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#archiveClose-list','fa-plus fa-fw','global.archive.close.transferSelecteds',undefined,'transferSelectedsDossiers'));
	            		}else if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'){
	            			$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#archiveClose-list','fa-plus fa-fw','global.archive.close.sendSelecteds',undefined,'sendSelectedsDossiers'));
	            		}
            		}
					$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#archiveClose-list', 'fa fa-download', 'global.literals.export', undefined, 'exportListClose'));
            	}else if(message.state.name == 'annexa.archive.transfer'){
            		$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#archiveTransferList-list', 'fa fa-download', 'global.tram.literals.export_dossier', undefined, 'exportDossier'));
                	$rootScope.subHeadButtons.push(new HeadButtonSearch('#tableFilter'));
	            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
	            		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
	            			$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#archiveTransferList-list','fa-plus fa-fw','global.archive.transfer.rejectSelecteds',undefined,'rejectSelectedsDossiers'));
	            			$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#archiveTransferList-list','fa-plus fa-fw','global.archive.transfer.acceptSelecteds',undefined,'acceptSelectedsDossiers'));
	            		}
            		}
					$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#archiveTransferList-list', 'fa fa-download', 'global.literals.export', undefined, 'exportListTransfer'));
                }else if(message.state.name == 'annexa.archive.rejected'){
                	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#archiveRejected-list', 'fa fa-download', 'global.tram.literals.export_dossier', undefined, 'exportDossier'));
                	$rootScope.subHeadButtons.push(new HeadButtonSearch('#tableFilter'));
	            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
	            		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
	            			$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#archiveRejected-list','fa-plus fa-fw','global.archive.close.transferSelecteds',undefined,'transferSelectedsDossiers'));
	            		}
            		}
  	            	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#archiveRejected-list', 'fa fa-download', 'global.literals.export', undefined, 'exportListRejected'));
            	}else if(message.state.name == 'annexa.archive.archive'){
            		$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#archiveArchive-list', 'fa fa-download', 'global.tram.literals.export_dossier', undefined, 'exportDossier'));
            		if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
	            		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
	            			$rootScope.subHeadButtons.push(new HeadButtonSearch('#tableFilter'));
	    	            	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#archiveArchive-list','fa-plus fa-fw','global.archive.archive.archiveSelecteds',undefined,'sendArchiveDefinitive'));
	            		}
            		}
  	            	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#archiveArchive-list', 'fa fa-download', 'global.literals.export', undefined, 'exportListArchive'));
            	}

                $rootScope.subHeadTabs = [
                    {
                        state: 'annexa.archive.finally',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.archive.finally' ? 'active' : ''),
                        name: 'global.archive.finally.title',
                        permissions: ['archive_finally']
                    },
                    {
                        state: 'annexa.archive.close',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.archive.close' ? 'active' : ''),
                        name: 'global.archive.close.title',
                        permissions: ['archive_close']
                    }
                 ];
            	 if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
            		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
	                	$rootScope.subHeadTabs.push({
	                        state: 'annexa.archive.transfer',
	                        type: 'breadDivButton ' + (message.state.name == 'annexa.archive.transfer' ? 'active' : ''),
	                        name: 'global.archive.transfer.title',
	                        permissions: ['archive_transfer']
	                    });
	                	$rootScope.subHeadTabs.push({
	                		state: 'annexa.archive.rejected',
	                		type: 'breadDivButton ' + (message.state.name == 'annexa.archive.rejected' ? 'active' : ''),
	                		name: 'global.archive.rejected.title',
	                		permissions: ['archive_rejected']
	                	});
                	}
                	if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'){
	                	$rootScope.subHeadTabs.push({
	                        state: 'annexa.archive.archive',
	                        type: 'breadDivButton ' + (message.state.name == 'annexa.archive.archive' ? 'active' : ''),
	                        name: 'global.archive.archive.title',
	                        permissions: ['archive_archive']
	                    });
                	}
            	 }
            }
            
            $scope.exportDossierCallServer = function (data, status, headers) {
                var contentType = data.headers('content-type');
                if (!contentType) {
                    contentType = 'application/zip';
                }
                var name = data.headers('content-disposition');
                if (name) {
                    name = name.split(";");
                    if (name && name.length > 1) {
                        name = name[1].trim().split("=");
                        if (name && name.length > 1) {
                            name = name[1];
                            name = name.replace(/"/g, '');
                        } else {
                            name = "documents.zip";
                        }
                    } else {
                        name = "documents.zip";
                    }
                } else {
                    name = "documents.zip";
                }
                var file = new Blob([data.data], {type: contentType});
                if (window.navigator.msSaveOrOpenBlob) {
                    try {
                        window.navigator.msSaveOrOpenBlob(file, name);
                    } catch (e) {
                        console.log(e.message);
                    }
                } else {

                    var linkElement = document.createElement('a');
                    try {
                        var url = window.URL.createObjectURL(file);
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute("download", name);
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        linkElement.dispatchEvent(clickEvent);
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        });

    	$scope.showError = function(data, errorDate, errorGeneral){
    		var error = "";
			if(data && data.errorDateAfter && data.errorDateAfter.length > 0){
				error += $filter('translate')(errorDate);
				error += "<br\>";
				var exist = false;
				_.forEach(data.errorDateAfter, function(err){
					error+=((!exist)?"":", ")+((err)?((err.code)?err.code:err.id):"");
					exist = true;
				});
				error += "<br\><br\>";
			}
			if(data && data.error && data.error.length > 0){
				error += $filter('translate')(errorGeneral);
				error += "<br\>";
				var exist = false;
				_.forEach(data.error, function(err){
					error+=((!exist)?"":", ")+((err)?((err.code)?err.code:err.id):"");
					exist = true;
				});
				error += "<br\>";
			}
			if(error && error != ""){
				DialogsFactory.error(error);
			}
    	}
        HeaderService.changeState($state.current);
        
        $scope.languageColumn = Language.getActiveColumn();
        $scope.archiveStates = [];
        $scope.dossiersLiteral = $filter('translate')('global.literals.trams');
        
    }])
    .controller('ArchiveFinallyController', ['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', 'DialogsFactory','$http', 'ErrorFactory', 'RestService', 
    function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory, DialogsFactory,$http, ErrorFactory, RestService) {
    	$scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });
        
    	$scope.finallySelectedRows = [];
    	$scope.finallyRowSelected = {};
    	$scope.finallySelectAll = false;
    	var addRouterState = function(){
    		var routerAux = {
       			 finallySelectedRows: $scope.finallySelectedRows,
       			 finallyRowSelected: $scope.finallyRowSelected,
       			 finallySelectAll:$scope.finallySelectAll
       	 	};
            RouteStateFactory.addRouteState($state.current, routerAux, 'ColumnsFilter');
    	}
    	
    	var initializeSelected = function () {
    		$scope.finallySelectedRows = [];
    		$scope.finallyRowSelected = {};
    		$scope.finallySelectAll = false;
    		addRouterState();
    		$scope.tableDefinition.reloadInternalData(true, true);
        }
    	
    	$scope.closeSelectedsDossiers = function(){
    		if($scope.finallySelectedRows && $scope.finallySelectedRows.length > 0){
    			DialogsFactory.confirm('global.archive.finally.closeSelecteds', 'global.archive.finally.closeSelectedsBody').then(function (data) {
    				TramFactory.archiveClose($scope.finallySelectedRows).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.finally.closeDateError", "global.archive.finally.closeGeneralError");
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.finally.closeSelectedError'));
                    });
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.finally.closeSelectedsNoSelected'));
    		}
    	}    	
    	$scope.finallySelectAllFunction = function(){
    		if($scope.finallySelectAll){
        		TramFactory.getAllIds('archiveFinally', getFilterCall(), getFilterCallAux()).then(function(data) {
        			$scope.finallySelectedRows.length = 0;
                	if(data && data.length > 0) {
                		_.forEach(data, function(dataId){
                			$scope.finallySelectedRows.push(dataId);
                			if(!$scope.finallyRowSelected){
                				$scope.finallyRowSelected = {};
                			}
                			$scope.finallyRowSelected[dataId] = true;
                		});
                    }
                	addRouterState();
                }).catch(function (error) {
                	$scope.finallySelectAll = false;
                });
        	}else{
        		$scope.finallySelectedRows.length = 0;
            	if($scope.finallyRowSelected){
        			var keys = Object.keys($scope.finallyRowSelected);
                    _.forEach(keys, function(key){
                    	$scope.finallyRowSelected[key] = false;
                    });
        		}
            	addRouterState();
        	}
        }
    	
    	$scope.finallySelectOne = function(id){
            var indexSelected = $linq($scope.finallySelectedRows).indexOf(" x => x == " + id);

            if (indexSelected != -1) {
            	$scope.finallySelectedRows.splice(indexSelected, 1);
            } else {
            	if($scope.tableDefinition.list){
            		var obj = $linq($scope.tableDefinition.list).firstOrDefault(undefined, "x => x.id == "+id);
					if(obj){
						$scope.finallySelectedRows.push(obj.id);
					}
            	}
            }
            if($scope.finallySelectedRows && $scope.tableDefinition && $scope.tableDefinition.totalRecords && $scope.tableDefinition.totalRecords == $scope.finallySelectedRows.length){
            	$scope.finallySelectAll = true;
            }else{
            	$scope.finallySelectAll = false;
            }
            addRouterState();
    	} 
    	var columnsAux = [
    		{ id: 'id', column: new ArchiveSelectAllColumn($filter, 'finallySelectAll', 'finallySelectAllFunction', 'finallySelectOne', 'finallyRowSelected', true, function(data){return true;}), sortable: false},
    		{ id: 'dossierResponsibleProfiles', column: new DossierResponsibleProfileColumn($filter, 'global.literals.responsibleProfiles', $scope.languageColumn), sortable: false},
    		{ id: 'dossierNumber', column: new TextColumn($filter, 'global.literals.code') },
    		{ id: 'procedure.'+$scope.languageColumn, column:  new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn)},
    		{ id: 'subject', column: new TextColumn($filter, 'global.literals.subject') },
    		{ id: 'endDate', column:  new DateColumn($filter, 'global.literals.endDate') },
    		{ id: 'closeExpireDate', column:  new DateColumn($filter, 'global.archive.finally.expiryCloseDate') },
    		{ id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
            		new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), 
            		[ 
            			new ActionButton('global.literals.close','closeArchive([data])')
            		]),sortable: false }
    	];
    	
    	var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
        	delete x['userProfiles'];
        	delete x['ficticio'];
        });
        
    	$scope.filter = [
    		{ id: 'responsibleTramitProfile', type: 'select-multiple', order: 0, label: 'global.literals.responsibleProfiles',  dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','responsibleTramitProfile', allProfilesBasic, 'id'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','responsibleTramitProfile')},
    		{ id: 'dossierNumber', type: 'text', order: 1, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','dossierNumber')},
    		{ id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','procedure'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','procedure')},
    		{ id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','subject')},
    		{ id: 'endDate', type: 'dateRange', order: 4, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','endDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','endDate') },
    		{ id: 'expiryCloseDate', type: 'dateRange', order: 5, label: 'global.archive.finally.expiryCloseDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','expiryCloseDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','expiryCloseDate') }
    	];
    	
    	if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.filter);
			}
		}else{
			var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.filter = routeState.state;
	        }
	        var routeStateColumnFilter = RouteStateFactory.getRouteState($state.current,'ColumnsFilter');
	        if(routeStateColumnFilter) {
	            if($rootScope.previousState && $rootScope.previousState.name == 'annexa.archive.finally.see'){
		        	var routerAux = routeStateColumnFilter.state;
		            $scope.finallySelectedRows = ((routerAux && routerAux.finallySelectedRows)?routerAux.finallySelectedRows:[]);
		    		$scope.finallyRowSelected = ((routerAux && routerAux.finallyRowSelected)?routerAux.finallyRowSelected:{});
		    		$scope.finallySelectAll = ((routerAux && routerAux.finallySelectAll != undefined)?routerAux.finallySelectAll:false);
	            }else{
	            	addRouterState();
	            }
	        }else{
	        	 addRouterState();
	        }
		}        
        var getFilterCall = function() {
        	return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function () {
        	var filter = TableFilterFactory.getFilterCallAux($scope.filter, true);
        	filter.filterOrigin = 'archiveFinally';
        	return filter;
        }
        
        $scope.viewElement = function(id) {
        	$state.transitionTo('annexa.archive.finally.see', {dossier: id});
        };
        
        $scope.closeArchive = function(id) {
        	if(id){
    			DialogsFactory.confirm('global.archive.finally.closeSelected', 'global.archive.finally.closeSelectedBody').then(function (data) {
    				TramFactory.archiveClose([id]).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.finally.closeDateError", "global.archive.finally.closeGeneralError");
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.finally.closeSelectedError'));
                    });
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.finally.closeSelectedsNoSelected'));
    		}
        };
   	
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_archive_finally', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_archive_finally', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossierNumber', sort: [[2,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
                id: 'tableArchiveFinally',
                origin: 'tram',
                objectType: 'Dossier',
                callOrigin: 'archiveFinally',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: getFilterCall(),
                filterCallAux: getFilterCallAux(),
                filterCallFunc: getFilterCall,
                filterCallAuxFunc: getFilterCallAux,
                columns: columns,
                containerScope: $scope,
				notAddExcel: true
            };
        
        $scope.exportDossier = function() {
        	if($scope.finallySelectedRows && $scope.finallySelectedRows.length > 0){
        		var send = {};
        		var dossiers = [];
        		angular.forEach($scope.finallySelectedRows, function (value) {
                    if (value) {
                    	dossiers.push({id:value});
                    }
                });
                dossiers = $linq(dossiers).orderBy("x => x.id").toArray();
                send.dossiers = JSOG.encode(dossiers);
                send.languageColumn = $scope.languageColumn;
                send.saveEniExp = false;
                $http({
                    url: './api/tram/exportDossier',
                    method: 'POST',
                    data: send,
                    headers: {
                        accept: 'application/zip'
                    },
                    responseType: 'arraybuffer',
                    cache: false
                }).then(function (data, status, headers) {
                	$scope.exportDossierCallServer(data,status,headers);
                }).catch(function (error) {
                    DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'foliate', error.data)));
                });
    		}else{
    			DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'foliate', {message: 'Not found documents'})));
    		}
        }

		$scope.exportListFinally = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}

    }])
    .controller('ArchiveCloseController', ['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', 'DialogsFactory', '$http', 'ErrorFactory', 'RestService', 
    function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory, DialogsFactory, $http, ErrorFactory, RestService) {
    	$scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });
        
    	$scope.closeSelectedRows = [];
    	$scope.closeRowSelected = {};
    	$scope.closeSelectAll = false;
    	var addRouterState = function(){
    		var routerAux = {
    			 closeSelectedRows: $scope.closeSelectedRows,
    			 closeRowSelected: $scope.closeRowSelected,
       			 closeSelectAll:$scope.closeSelectAll
       	 	};
            RouteStateFactory.addRouteState($state.current, routerAux, 'ColumnsFilter');
    	}
    	
    	var initializeSelected = function () {
    		$scope.closeSelectedRows = [];
    		$scope.closeRowSelected = {};
    		$scope.closeSelectAll = false;
    		addRouterState();
    		$scope.tableDefinition.reloadInternalData(true, true);
        }
    	
    	$scope.transferSelectedsDossiers = function(){
    		if($scope.closeSelectedRows && $scope.closeSelectedRows.length > 0){
    			DialogsFactory.confirm('global.archive.close.transferSelecteds', 'global.archive.close.transferSelectedsBody').then(function (data) {
    				TramFactory.archiveTransfer($scope.closeSelectedRows).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.close.transferDateError", "global.archive.close.transferGeneralError");
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.close.transferSelectedsNoSelected'));
                    });
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.close.transferSelectedsNoSelected'));
    		}
    	} 
    	
    	$scope.sendSelectedsDossiers = function(){
    		if($scope.closeSelectedRows && $scope.closeSelectedRows.length > 0){
    			DialogsFactory.confirm('global.archive.close.sendSelecteds', 'global.archive.close.sendSelectedsBody').then(function (data) {
    				TramFactory.archiveDefinitive($scope.closeSelectedRows).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.close.sendDateError", "global.archive.close.sendGeneralError");
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.close.sendSelectedsNoSelected'));
                    });
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.close.sendSelectedsNoSelected'));
    		}
    	} 
    	
    	$scope.closeSelectAllFunction = function(){
    		if($scope.closeSelectAll){
        		TramFactory.getAllIds('archiveClose', getFilterCall(), getFilterCallAux()).then(function(data) {
        			$scope.closeSelectedRows.length = 0;
                	if(data && data.length > 0) {
                		_.forEach(data, function(dataId){
                			$scope.closeSelectedRows.push(dataId);
                			if(!$scope.closeRowSelected){
                				$scope.closeRowSelected = {};
                			}
                			$scope.closeRowSelected[dataId] = true;
                		});
                    }
                	addRouterState();
                }).catch(function (error) {
                	$scope.closeSelectAll = false;
                });
        	}else{
        		$scope.closeSelectedRows.length = 0;
            	if($scope.closeRowSelected){
        			var keys = Object.keys($scope.closeRowSelected);
                    _.forEach(keys, function(key){
                    	$scope.closeRowSelected[key] = false;
                    });
        		}
            	addRouterState();
        	}
        }
    	
    	$scope.closeSelectOne = function(id){
            var indexSelected = $linq($scope.closeSelectedRows).indexOf(" x => x == " + id);

            if (indexSelected != -1) {
            	$scope.closeSelectedRows.splice(indexSelected, 1);
            } else {
            	if($scope.tableDefinition.list){
            		var obj = $linq($scope.tableDefinition.list).firstOrDefault(undefined, "x => x.id == "+id);
					if(obj){
						$scope.closeSelectedRows.push(obj.id);
					}
            	}
            }
            if($scope.closeSelectedRows && $scope.tableDefinition && $scope.tableDefinition.totalRecords && $scope.tableDefinition.totalRecords == $scope.closeSelectedRows.length){
            	$scope.closeSelectAll = true;
            }else{
            	$scope.closeSelectAll = false;
            }
            addRouterState();
    	} 
    	var columnsAux = [
    		{ id: 'id', column: new ArchiveSelectAllColumn($filter, 'closeSelectAll', 'closeSelectAllFunction', 'closeSelectOne', 'closeRowSelected', true, function(data){return true;}), sortable: false},
    		{ id: 'dossierResponsibleProfiles', column: new DossierResponsibleProfileColumn($filter, 'global.literals.responsibleProfiles', $scope.languageColumn), sortable: false},
    		{ id: 'dossierNumber', column: new TextColumn($filter, 'global.literals.code') },
    		{ id: 'procedure.'+$scope.languageColumn, column:  new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn)},
    		{ id: 'subject', column: new TextColumn($filter, 'global.literals.subject') },
    		{ id: 'closeDate', column:  new DateColumn($filter, 'global.archive.close.closeDate') }
    	];
    	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
    		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
    			columnsAux.push({ id: 'transferExpireDate', column:  new DateColumn($filter, 'global.archive.close.expiryTransferDate') });
    			columnsAux.push({ id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
                		new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), 
                		[ 
                			new ActionButton('global.archive.close.transferSelected','transferArchive([data])', undefined, undefined,function(data, type, full, meta){
                				if(full && full.transferExpireDate && new Date() >= new Date(full.transferExpireDate)){
                	    			return true;
                	    		}else{
                	    			return false;
                	    		}
                			})
                		]),sortable: false });
    		}else if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'){
    			columnsAux.push({ id: 'archiveDefinitiveExpireDate', column:  new DateColumn($filter, 'global.archive.close.expiryArchiveDate') });
    			columnsAux.push({ id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
                		new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), 
                		[ 
                			new ActionButton('global.archive.close.sendSelected','sendArchive([data])', undefined, undefined,function(data, type, full, meta){
                				if(full && full.archiveDefinitiveExpireDate && new Date() >= new Date(full.archiveDefinitiveExpireDate)){
                	    			return true;
                	    		}else{
                	    			return false;
                	    		}
                			})
                		]),sortable: false });
    		}
		}
    	
    	var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
        	delete x['userProfiles'];
        	delete x['ficticio'];
        });
        
    	$scope.filter = [
    		{ id: 'responsibleTramitProfile', type: 'select-multiple', order: 0, label: 'global.literals.responsibleProfiles',  dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','responsibleTramitProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveClose','responsibleTramitProfile')},
    		{ id: 'dossierNumber', type: 'text', order: 1, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveClose','dossierNumber')},
    		{ id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','procedure'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveClose','procedure')},
    		{ id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveClose','subject')},
    		{ id: 'closeDate', type: 'dateRange', order: 4, label: 'global.archive.close.closeDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','closeDate') , negated:HelperService.getPrefilterNegated('tramitation','tableArchiveClose','closeDate')},
    	];
    	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
    		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
    			$scope.filter.push({ id: 'transferExpireDate', type: 'dateRange', order: 5, label: 'global.archive.close.expiryTransferDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','transferExpireDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveClose','transferExpireDate') });
    		}else if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'){
    			$scope.filter.push({ id: 'archiveExpireDate', type: 'dateRange', order: 5, label: 'global.archive.close.expiryArchiveDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','archiveExpireDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveClose','archiveExpireDate') });
    		}
		}
    	$scope.filter.push({ id: 'allClosed', type: 'checkbox', order: 6, label: 'global.archive.close.allClosed', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','allClosed')});
		if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.filter);
			}
		}else{
			var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.filter = routeState.state;
	        }
	        var routeStateColumnFilter = RouteStateFactory.getRouteState($state.current,'ColumnsFilter');
	        if(routeStateColumnFilter) {
	            if($rootScope.previousState && $rootScope.previousState.name == 'annexa.archive.close.see'){
		        	var routerAux = routeStateColumnFilter.state;
		            $scope.closeSelectedRows = ((routerAux && routerAux.closeSelectedRows)?routerAux.closeSelectedRows:[]);
		    		$scope.closeRowSelected = ((routerAux && routerAux.closeRowSelected)?routerAux.closeRowSelected:{});
		    		$scope.closeSelectAll = ((routerAux && routerAux.closeSelectAll != undefined)?routerAux.closeSelectAll:false);
	            }else{
	            	addRouterState();
	            }
	        }else{
	        	 addRouterState();
	        }
		}        
        var getFilterCall = function() {
        	return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function () {
        	var filter = TableFilterFactory.getFilterCallAux($scope.filter, true);
        	filter.filterOrigin = 'archiveClose';
        	return filter;
        }
        
        $scope.viewElement = function(id) {
        	$state.transitionTo('annexa.archive.close.see', {dossier: id});
        };
        
        $scope.transferArchive = function(id) {
        	if(id){
    			DialogsFactory.confirm('global.archive.close.transferSelected', 'global.archive.close.transferSelectedBody').then(function (data) {
    				TramFactory.archiveTransfer([id]).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.close.transferDateError", "global.archive.close.transferGeneralError");
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.close.transferSelectedsNoSelected'));
                    });
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.close.transferSelectedsNoSelected'));
    		}
        };
        
        $scope.sendArchive = function(id) {
        	if(id){
    			DialogsFactory.confirm('global.archive.close.closeSelected', 'global.archive.close.sendSelectedBody').then(function (data) {
    				TramFactory.archiveDefinitive([id]).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.close.sendDateError", "global.archive.close.sendGeneralError");
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.close.sendSelectedsNoSelected'));
                    });
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.close.sendSelectedsNoSelected'));
    		}
        };
   	
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_archive_close', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_archive_close', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossierNumber', sort: [[2,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

       $scope.tableDefinition = {
                id: 'tableArchiveClose',
                origin: 'tram',
                objectType: 'Dossier',
                callOrigin: 'archiveClose',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: getFilterCall(),
                filterCallAux: getFilterCallAux(),
                filterCallFunc: getFilterCall,
                filterCallAuxFunc: getFilterCallAux,
                columns: columns,
                containerScope: $scope,
				notAddExcel: true
            };
       
       $scope.$on('tableFilterSubmitChange',function(event, args){
	       	if(args && args.id == $scope.tableDefinition.id){
	       		$scope.closeSelectedRows = [];
	    		$scope.closeRowSelected = {};
	    		$scope.closeSelectAll = false;
	    		addRouterState();
	       	}
       });

       $scope.exportDossier = function() {
       	if($scope.closeSelectedRows && $scope.closeSelectedRows.length > 0){
       		var send = {};
       		var dossiers = [];
       		angular.forEach($scope.closeSelectedRows, function (value) {
                   if (value) {
                   	dossiers.push({id:value});
                   }
               });
               dossiers = $linq(dossiers).orderBy("x => x.id").toArray();
               send.dossiers = JSOG.encode(dossiers);
               send.languageColumn = $scope.languageColumn;
               send.saveEniExp = false;
               $http({
                   url: './api/tram/exportDossier',
                   method: 'POST',
                   data: send,
                   headers: {
                       accept: 'application/zip'
                   },
                   responseType: 'arraybuffer',
                   cache: false
               }).then(function (data, status, headers) {
               	$scope.exportDossierCallServer(data,status,headers);
               }).catch(function (error) {
                   DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'foliate', error.data)));
               });
   		}else{
   			DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'foliate', {message: 'Not found documents'})));
   		}
       }

		$scope.exportListClose = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}

    }])
    .controller('ArchiveTransferController', ['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', 'DialogsFactory', 'CommonService', 'AnnexaFormlyFactory', '$http', 'ErrorFactory', 'RestService', 
    function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory, DialogsFactory, CommonService, AnnexaFormlyFactory, $http, ErrorFactory, RestService) {
    	$scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });
        
    	$scope.transferSelectedRows = [];
    	$scope.transferRowSelected = {};
    	$scope.transferSelectAll = false;
    
    	$scope.active = undefined;
    	$scope.departmentSelected = undefined;
    	$scope.classificationSelected = undefined;
    	$scope.profilesNums = {};
    	$scope.allProfiles = angular.copy(GlobalDataFactory.allProfiles);
    	$scope.qdCNums = {};
    	$scope.qdCTree = angular.copy(GlobalDataFactory.archiveClassifications);
    	$scope.qdCTree = CommonService.getNestableData($scope.qdCTree, 'archiveClassification', 'orderView');
    	
    	var addRouterState = function(){
    		var routerAux = {
    			active: $scope.active,
    			departmentSelected: $scope.departmentSelected,
    			classificationSelected: $scope.classificationSelected
       	 	};
            RouteStateFactory.addRouteState($state.current, routerAux, 'TabsFilter');
    	}
    	
    	var addRouterStateColumnsFilter = function(){
    		var routerAux = {
    			transferSelectedRows: $scope.transferSelectedRows,
       			transferRowSelected: $scope.transferRowSelected,
       			transferSelectAll:$scope.transferSelectAll
       	 	};
            RouteStateFactory.addRouteState($state.current, routerAux, 'ColumnsFilter');
    	}
    	
    	var initializeSelected = function () {
    		$scope.transferSelectedRows = [];
    		$scope.transferRowSelected = {};
    		$scope.transferSelectAll = false;
    		addRouterStateColumnsFilter();
        }
    	
    	var routeStateTabs = RouteStateFactory.getRouteState($state.current,'TabsFilter');
        if(routeStateTabs && routeStateTabs.state) {
        	$scope.departmentSelected = routeStateTabs.state.departmentSelected;
        	$scope.classificationSelected = routeStateTabs.state.classificationSelected;
            $scope.active = routeStateTabs.state.active;
        }else{
			$scope.departmentSelected = undefined;
			$scope.classificationSelected = undefined;
			$scope.active = 'all';
        }
    	
    	$scope.changeViewType = function(type){
    		$scope.departmentSelected = undefined;
    		$scope.classificationSelected = undefined;
    		$scope.active = type;
    		addRouterState();
    		initializeSelected();
    		if(type == 'all'){
    			$scope.tableDefinition.reloadInternalData(true, true);
    		}else if(type == 'department'){
    			$scope.profilesNums = {};
    			TramFactory.getCounters('archiveTransfer', getFilterCall(), getFilterCallAux(), 'Profiles').then(function(data) {
    				if(data && data.length > 0){
    					_.forEach(data, function(obj){
    						if(obj && obj.length == 2 && obj[0]){
    							$scope.profilesNums[obj[0]] = obj[1];
    						}
    					});
    				}
    			}).catch(function (error) {});
    		}else if(type == 'classification'){
    			$scope.qdCNums = {};
    			TramFactory.getCounters('archiveTransfer', getFilterCall(), getFilterCallAux(), 'QdC').then(function(data) {
    				if(data && data.length > 0){
    					_.forEach(data, function(obj){
    						if(obj && obj.length == 2 && obj[0]){
    							$scope.qdCNums[obj[0]] = obj[1];
    						}
    					});
    				}
    			}).catch(function (error) {});
    		}
    	}
    	
    	$scope.transitionToArchiveTransferType = function(type, idSelected){
    		if($scope.active == 'all'){
    			$scope.departmentSelected = undefined;
				$scope.classificationSelected = undefined;
    		}else if($scope.active == 'department'){
    			$scope.departmentSelected = idSelected;
				$scope.classificationSelected = undefined;
    		}else if($scope.active == 'classification'){
    			$scope.departmentSelected = undefined;
				$scope.classificationSelected = idSelected;
    		}
    		addRouterState();
    		initializeSelected();
    		$scope.tableDefinition.reloadInternalData(true, true);
    	}
    
    	$scope.acceptSelectedsDossiers = function(){
    		if($scope.transferSelectedRows && $scope.transferSelectedRows.length > 0){
    			DialogsFactory.confirm('global.archive.transfer.acceptSelecteds', 'global.archive.transfer.acceptSelectedsBody').then(function (data) {
    		    	TramFactory.archiveArchive($scope.transferSelectedRows).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.transfer.acceptGeneralError", "global.archive.transfer.acceptGeneralError");
    					$scope.tableDefinition.reloadInternalData(true, true);
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.transfer.acceptSelectedError'));
                    });
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.transfer.acceptSelectedsNoSelected'));
    		}
    	}   
    	$scope.rejectSelectedsDossiers = function(){
    		if($scope.transferSelectedRows && $scope.transferSelectedRows.length > 0){
    			DialogsFactory.confirm('global.archive.transfer.rejectSelecteds', 'global.archive.transfer.rejectSelectedsBody').then(function (data) {
    				showModal('reject', $scope.transferSelectedRows);
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.transfer.rejectSelectedsNoSelected'));
    		}
    	}   
    	
    	$scope.transferSelectAllFunction = function(){
    		if($scope.transferSelectAll){
        		TramFactory.getAllIds('archiveTransfer', getFilterCall(), getFilterCallAux()).then(function(data) {
        			$scope.transferSelectedRows.length = 0;
                	if(data && data.length > 0) {
                		_.forEach(data, function(dataId){
                			$scope.transferSelectedRows.push(dataId);
                			if(!$scope.transferRowSelected){
                				$scope.transferRowSelected = {};
                			}
                			$scope.transferRowSelected[dataId] = true;
                		});
                    }
                	addRouterStateColumnsFilter();
                }).catch(function (error) {
                	$scope.transferSelectAll = false;
                });
        	}else{
        		$scope.transferSelectedRows.length = 0;
            	if($scope.transferRowSelected){
        			var keys = Object.keys($scope.transferRowSelected);
                    _.forEach(keys, function(key){
                    	$scope.transferRowSelected[key] = false;
                    });
        		}
            	addRouterStateColumnsFilter();
        	}
        }
    	
    	$scope.transferSelectOne = function(id){
            var indexSelected = $linq($scope.transferSelectedRows).indexOf(" x => x == " + id);

            if (indexSelected != -1) {
            	$scope.transferSelectedRows.splice(indexSelected, 1);
            } else {
            	if($scope.tableDefinition.list){
            		var obj = $linq($scope.tableDefinition.list).firstOrDefault(undefined, "x => x.id == "+id);
					if(obj){
						$scope.transferSelectedRows.push(obj.id);
					}
            	}
            }
            if($scope.transferSelectedRows && $scope.tableDefinition && $scope.tableDefinition.totalRecords && $scope.tableDefinition.totalRecords == $scope.transferSelectedRows.length){
            	$scope.transferSelectAll = true;
            }else{
            	$scope.transferSelectAll = false;
            }
            addRouterStateColumnsFilter();
    	} 
    	var columnsAux = [
    		{ id: 'id', column: new ArchiveSelectAllColumn($filter, 'transferSelectAll', 'transferSelectAllFunction', 'transferSelectOne', 'transferRowSelected', true, function(data){return true}), sortable: false},
    		{ id: 'dossierResponsibleProfiles', column: new DossierResponsibleProfileColumn($filter, 'global.literals.responsibleProfiles', $scope.languageColumn), sortable: false},
    		{ id: 'dossierNumber', column: new TextColumn($filter, 'global.literals.code') },
    		{ id: 'procedure.'+$scope.languageColumn, column:  new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn)},
    		{ id: 'subject', column: new TextColumn($filter, 'global.literals.subject') },
    		{ id: 'transferDate', column:  new DateColumn($filter, 'global.archive.transfer.transferDate') },
    		{ id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
            		new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), 
            		[ 
            			new ActionButton('global.archive.transfer.accept','acceptArchive([data])'),
            			new ActionButton('global.archive.transfer.reject','rejectArchive([data])')
            		]),sortable: false }
    	];
    	
    	var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
        	delete x['userProfiles'];
        	delete x['ficticio'];
        });
        
    	$scope.filter = [
    		{ id: 'responsibleTramitProfile', type: 'select-multiple', order: 0, label: 'global.literals.responsibleProfiles',  dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveTransfer','responsibleTramitProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveTransfer','responsibleTramitProfile')},
    		{ id: 'dossierNumber', type: 'text', order: 1, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveTransfer','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveTransfer','dossierNumber')},
    		{ id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveTransfer','procedure'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveTransfer','procedure')},
    		{ id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveTransfer','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveTransfer','subject')},
    		{ id: 'transferDate', type: 'dateRange', order: 4, label: 'global.archive.transfer.transferDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveTransfer','transferDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveTransfer','transferDate') }
    	];
    	if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.filter);
			}
		}else{
			var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.filter = routeState.state;
	        }
	        var routeStateColumnFilter = RouteStateFactory.getRouteState($state.current,'ColumnsFilter');
	        if(routeStateColumnFilter) {
	            if($rootScope.previousState && $rootScope.previousState.name == 'annexa.archive.transfer.edit'){
		        	var routerAux = routeStateColumnFilter.state;
		            $scope.transferSelectedRows = ((routerAux && routerAux.transferSelectedRows)?routerAux.transferSelectedRows:[]);
		    		$scope.transferRowSelected = ((routerAux && routerAux.transferRowSelected)?routerAux.transferRowSelected:{});
		    		$scope.transferSelectAll = ((routerAux && routerAux.transferSelectAll != undefined)?routerAux.transferSelectAll:false);
	            }else{
	            	addRouterStateColumnsFilter();
	            }
	        }else{
	        	addRouterStateColumnsFilter();
	        }
		}
        
        var getFilterCall = function() {
        	return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function () {
        	var filter = TableFilterFactory.getFilterCallAux($scope.filter, true);
        	filter.filterOrigin = 'archiveTransfer';
        	if($scope.classificationSelected){
        		filter.classification = $scope.classificationSelected;
        	}
        	if($scope.departmentSelected){
        		filter.department = $scope.departmentSelected;
        	}
        	return filter;
        }
        
        $scope.viewElement = function(id) {
        	$state.transitionTo('annexa.archive.transfer.edit', {dossier: id});
        };
        
        $scope.acceptArchive = function(id) {
        	if(id){
    			DialogsFactory.confirm('global.archive.transfer.acceptSelected', 'global.archive.transfer.acceptSelectedBody').then(function (data) {
    		    	TramFactory.archiveArchive([id]).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.transfer.acceptGeneralError", "global.archive.transfer.acceptGeneralError");
    					$scope.tableDefinition.reloadInternalData(true, true);
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.transfer.acceptSelectedError'));
                    });
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.transfer.acceptSelectedsNoSelected'));
    		}
        };
   	
        $scope.rejectArchive = function(id) {
        	if(id){
    			DialogsFactory.confirm('global.archive.transfer.rejectSelected', 'global.archive.transfer.rejectSelectedBody').then(function (data) {
    				showModal('reject', [id]);
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.transfer.rejectSelectedsNoSelected'));
    		}
        };
   	
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_archive_transfer', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_archive_transfer', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossierNumber', sort: [[2,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
            id: 'tableArchiveTransfer',
            origin: 'tram',
            objectType: 'Dossier',
            callOrigin: 'archiveTransfer',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope,
			notAddExcel: true
        };
        
        $scope.toggle = function (scope) {
            scope.toggle();
        };

        $scope.treeOptions = {
            beforeDrop : function (e) {
            	var deferred = $q.defer();
            	deferred.reject(false);
	            return deferred.promise;    
            }
        };
        var showModal = function(type, ids) {
            var modalToShow = {
                title: 'global.archive.transfer.reject',
                size: '',
                annexaFormly: {
                    fields: [
                        {
                            key: 'modal_body',
                            className: 'modal-body p-lg',
                            fieldGroup: [
                            	{ 
                            		key: 'reason', 
                            		type: 'annexaTextAreaRow', 
                            		className: 'col-sm-12', 
                            		templateOptions: {
                            			type: 'text',
                                        label: 'global.literals.reason',
                                        required: true,
                                        focus: false
                            		} 
                            	}
                            ]
                        }
                    ]
                },
                alerts: [],
                submitModal: function () {}
            }
        	modalToShow.annexaFormly.model = {};
        	modalToShow.annexaFormly.model.modal_body = {};
        	modalToShow.annexaFormly.options = {};
        	modalToShow.annexaFormly.options.formState = {readOnly: false};
        
            var submitReasonModal = function() {
            	var self = this;
                this.annexaFormly.form.$setSubmitted();

                if(this.annexaFormly.form.$valid) {
                	TramFactory.archiveReject(ids, this.annexaFormly.model.modal_body.reason).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.transfer.rejectGeneralError", "global.archive.transfer.rejectGeneralError");
    					$scope.tableDefinition.reloadInternalData(true, true);
    					self.close();
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.transfer.rejectSelectedError'));
                    });  
                }
            }

            AnnexaFormlyFactory.showModal('modalTransferReasonModal', modalToShow, submitReasonModal, false);
        }
        
        $scope.$on('tableFilterSubmitChange',function(event, args){
	       	if(args && args.id == $scope.tableDefinition.id){
	       		initializeSelected();
	       	}
       });
        
        $scope.exportDossier = function() {
        	if($scope.transferSelectedRows && $scope.transferSelectedRows.length > 0){
        		var send = {};
        		var dossiers = [];
        		angular.forEach($scope.transferSelectedRows, function (value) {
                    if (value) {
                    	dossiers.push({id:value});
                    }
                });
                dossiers = $linq(dossiers).orderBy("x => x.id").toArray();
                send.dossiers = JSOG.encode(dossiers);
                send.languageColumn = $scope.languageColumn;
                send.saveEniExp = false;
                $http({
                    url: './api/tram/exportDossier',
                    method: 'POST',
                    data: send,
                    headers: {
                        accept: 'application/zip'
                    },
                    responseType: 'arraybuffer',
                    cache: false
                }).then(function (data, status, headers) {
                	$scope.exportDossierCallServer(data,status,headers);
                }).catch(function (error) {
                    DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'foliate', error.data)));
                });
    		}else{
    			DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'foliate', {message: 'Not found documents'})));
    		}
        }

		$scope.exportListTransfer = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}

    }])
    .controller('ArchiveRejectedController', ['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', 'DialogsFactory','$http', 'ErrorFactory', 'RestService', 
    function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory, DialogsFactory,$http, ErrorFactory, RestService) {
    	$scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });
        
    	$scope.rejectedSelectedRows = [];
    	$scope.rejectedRowSelected = {};
    	$scope.rejectedSelectAll = false;
    	var addRouterState = function(){
    		var routerAux = {
    			rejectedSelectedRows: $scope.rejectedSelectedRows,
       			rejectedRowSelected: $scope.rejectedRowSelected,
       			rejectedSelectAll:$scope.rejectedSelectAll
       	 	};
            RouteStateFactory.addRouteState($state.current, routerAux, 'ColumnsFilter');
    	}
    	
    	var initializeSelected = function () {
    		$scope.rejectedSelectedRows = [];
    		$scope.rejectedRowSelected = {};
    		$scope.rejectedSelectAll = false;
    		addRouterState();
    		$scope.tableDefinition.reloadInternalData(true, true);
        }
    	
    	$scope.transferSelectedsDossiers = function(){
    		if($scope.rejectedSelectedRows && $scope.rejectedSelectedRows.length > 0){
    			DialogsFactory.confirm('global.archive.close.transferSelecteds', 'global.archive.close.transferSelectedsBody').then(function (data) {
    				TramFactory.archiveTransfer($scope.rejectedSelectedRows).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.close.transferDateError", "global.archive.close.transferGeneralError");
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.close.transferSelectedsNoSelected'));
                    }); 
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.close.transferSelectedsNoSelected'));
    		}
    	}    	
    	$scope.rejectedSelectAllFunction = function(){
    		if($scope.rejectedSelectAll){
        		TramFactory.getAllIds('archiveRejected', getFilterCall(), getFilterCallAux()).then(function(data) {
        			$scope.rejectedSelectedRows.length = 0;
                	if(data && data.length > 0) {
                		_.forEach(data, function(dataId){
                			$scope.rejectedSelectedRows.push(dataId);
                			if(!$scope.rejectedRowSelected){
                				$scope.rejectedRowSelected = {};
                			}
                			$scope.rejectedRowSelected[dataId] = true;
                		});
                    }
                	addRouterState();
                }).catch(function (error) {
                	$scope.rejectedSelectAll = false;
                });
        	}else{
        		$scope.rejectedSelectedRows.length = 0;
            	if($scope.rejectedRowSelected){
        			var keys = Object.keys($scope.rejectedRowSelected);
                    _.forEach(keys, function(key){
                    	$scope.rejectedRowSelected[key] = false;
                    });
        		}
            	addRouterState();
        	}
        }
    	
    	$scope.rejectedSelectOne = function(id){
            var indexSelected = $linq($scope.rejectedSelectedRows).indexOf(" x => x == " + id);

            if (indexSelected != -1) {
            	$scope.rejectedSelectedRows.splice(indexSelected, 1);
            } else {
            	if($scope.tableDefinition.list){
            		var obj = $linq($scope.tableDefinition.list).firstOrDefault(undefined, "x => x.id == "+id);
					if(obj){
						$scope.rejectedSelectedRows.push(obj.id);
					}
            	}
            }
            if($scope.rejectedSelectedRows && $scope.tableDefinition && $scope.tableDefinition.totalRecords && $scope.tableDefinition.totalRecords == $scope.rejectedSelectedRows.length){
            	$scope.rejectedSelectAll = true;
            }else{
            	$scope.rejectedSelectAll = false;
            }
            addRouterState();
    	} 
    	var renderCommentColumn = function(data, type, full, meta) {
            return '<table-data-comment-column  row-property="' + data + '" can-create-comment="false"></table-data-comment-column>';
        }
    	var columnsAux = [
    		{ id: 'id', column: new ArchiveSelectAllColumn($filter, 'rejectedSelectAll', 'rejectedSelectAllFunction', 'rejectedSelectOne', 'rejectedRowSelected', true, function(data){return true}), sortable: false},
    		{ id: 'dossierResponsibleProfiles', column: new DossierResponsibleProfileColumn($filter, 'global.literals.responsibleProfiles', $scope.languageColumn), sortable: false},
    		{ id: 'dossierNumber', column: new TextColumn($filter, 'global.literals.code') },
    		{ id: 'procedure.'+$scope.languageColumn, column:  new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn)},
    		{ id: 'subject', column: new TextColumn($filter, 'global.literals.subject') },
    		{ id: 'rejectedDate', column:  new DateColumn($filter, 'global.archive.rejected.rejectedDate') },
    		{ id: 'archiveRejectReason', width: '100px', title: $filter('translate')('global.literals.comments'), render: renderCommentColumn, sortable: false },
    		{ id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
            		new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), 
            		[ 
            			new ActionButton('global.archive.close.transferSelected','transferArchive([data])')
            		]),sortable: false }
    	];
    	
    	var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
        	delete x['userProfiles'];
        	delete x['ficticio'];
        });
        
    	$scope.filter = [
    		{ id: 'responsibleTramitProfile', type: 'select-multiple', order: 0, label: 'global.literals.responsibleProfiles',  dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveRejected','responsibleTramitProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveRejected','responsibleTramitProfile')},
    		{ id: 'dossierNumber', type: 'text', order: 1, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveRejected','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveRejected','dossierNumber')},
    		{ id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveRejected','procedure'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveRejected','procedure')},
    		{ id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveRejected','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveRejected','subject')},
    		{ id: 'rejectedDate', type: 'dateRange', order: 4, label: 'global.archive.rejected.rejectedDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveRejected','rejectedDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveRejected','rejectedDate') }
    	];
    	if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.filter);
			}
		}else{
			var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.filter = routeState.state;
	        }
	        var routeStateColumnFilter = RouteStateFactory.getRouteState($state.current,'ColumnsFilter');
	        if(routeStateColumnFilter) {
	            if($rootScope.previousState && $rootScope.previousState.name == 'annexa.archive.rejected.edit'){
		        	var routerAux = routeStateColumnFilter.state;
		            $scope.rejectedSelectedRows = ((routerAux && routerAux.rejectedSelectedRows)?routerAux.rejectedSelectedRows:[]);
		    		$scope.rejectedRowSelected = ((routerAux && routerAux.rejectedRowSelected)?routerAux.rejectedRowSelected:{});
		    		$scope.rejectedSelectAll = ((routerAux && routerAux.rejectedSelectAll != undefined)?routerAux.rejectedSelectAll:false);
	            }else{
	            	addRouterState();
	            }
	        }else{
	        	 addRouterState();
	        }
        }
        var getFilterCall = function() {
        	return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function () {
        	var filter = TableFilterFactory.getFilterCallAux($scope.filter, true);
        	filter.filterOrigin = 'archiveRejected';
        	return filter;
        }
        
        $scope.viewElement = function(id) {
        	$state.transitionTo('annexa.archive.rejected.edit', {dossier: id});
        };
        
        $scope.transferArchive = function(id) {
        	if(id){
    			DialogsFactory.confirm('global.archive.close.transferSelected', 'global.archive.close.transferSelectedBody').then(function (data) {
    				TramFactory.archiveTransfer([id]).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.close.transferDateError", "global.archive.close.transferGeneralError");
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.close.transferSelectedError'));
                    }); 
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.close.transferSelectedsNoSelected'));
    		}
        };
   	
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_archive_rejected', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_archive_rejected', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossierNumber', sort: [[2,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
                id: 'tableArchiveRejected',
                origin: 'tram',
                objectType: 'Dossier',
                callOrigin: 'archiveRejected',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: getFilterCall(),
                filterCallAux: getFilterCallAux(),
                filterCallFunc: getFilterCall,
                filterCallAuxFunc: getFilterCallAux,
                columns: columns,
                containerScope: $scope,
				notAddExcel: true
            };
        
        $scope.exportDossier = function() {
        	if($scope.rejectedSelectedRows && $scope.rejectedSelectedRows.length > 0){
        		var send = {};
        		var dossiers = [];
        		angular.forEach($scope.rejectedSelectedRows, function (value) {
                    if (value) {
                    	dossiers.push({id:value});
                    }
                });
                dossiers = $linq(dossiers).orderBy("x => x.id").toArray();
                send.dossiers = JSOG.encode(dossiers);
                send.languageColumn = $scope.languageColumn;
                send.saveEniExp = false;
                $http({
                    url: './api/tram/exportDossier',
                    method: 'POST',
                    data: send,
                    headers: {
                        accept: 'application/zip'
                    },
                    responseType: 'arraybuffer',
                    cache: false
                }).then(function (data, status, headers) {
                	$scope.exportDossierCallServer(data,status,headers);
                }).catch(function (error) {
                    DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'foliate', error.data)));
                });
    		}else{
    			DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'foliate', {message: 'Not found documents'})));
    		}
        }

		$scope.exportListRejected = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}
    }])
    .controller('ArchiveArchiveController', ['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', 'DialogsFactory', 'CommonService', '$http', 'ErrorFactory', 'RestService', 
    function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory, DialogsFactory, CommonService,$http,ErrorFactory, RestService) {
    	$scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });
        
    	$scope.archiveSelectedRows = [];
    	$scope.archiveRowSelected = {};
    	$scope.archiveSelectAll = false;
    	
    	$scope.active = undefined;
    	$scope.classificationSelected = undefined;

    	$scope.qdCNums = {};
    	$scope.qdCTree = angular.copy(GlobalDataFactory.archiveClassifications);
    	$scope.qdCTree = CommonService.getNestableData($scope.qdCTree, 'archiveClassification', 'orderView');
    	var addRouterStateTabs = function(){
    		var routerAux = {
    			active: $scope.active,
    			classificationSelected: $scope.classificationSelected
       	 	};
            RouteStateFactory.addRouteState($state.current, routerAux, 'TabsFilter');
    	}
    	
    	var addRouterState = function(){
    		var routerAux = {
    			archiveSelectedRows: $scope.archiveSelectedRows,
    			archiveRowSelected: $scope.archiveRowSelected,
       			archiveSelectAll:$scope.archiveSelectAll
       	 	};
            RouteStateFactory.addRouteState($state.current, routerAux, 'ColumnsFilter');
    	}
    	
    	var initializeSelected = function () {
    		$scope.archiveSelectedRows = [];
    		$scope.archiveRowSelected = {};
    		$scope.archiveSelectAll = false;
    		addRouterState();
        }
    	
    	var routeStateTabs = RouteStateFactory.getRouteState($state.current,'TabsFilter');
        if(routeStateTabs && routeStateTabs.state) {
        	$scope.classificationSelected = routeStateTabs.state.classificationSelected;
            $scope.active = routeStateTabs.state.active;
        }else{
			$scope.departmentSelected = undefined;
			$scope.classificationSelected = undefined;
			$scope.active = 'all';
        }
    	
    	$scope.changeViewType = function(type){
    		$scope.classificationSelected = undefined;
    		$scope.active = type;
    		addRouterStateTabs();
    		initializeSelected();
    		if(type == 'all'){
    			$scope.tableDefinition.reloadInternalData(true, true);
    		}else if(type == 'classification'){
    			$scope.qdCNums = {};
    			TramFactory.getCounters('archiveArchive', getFilterCall(), getFilterCallAux(), 'QdC').then(function(data) {
    				if(data && data.length > 0){
    					_.forEach(data, function(obj){
    						if(obj && obj.length == 2 && obj[0]){
    							$scope.qdCNums[obj[0]] = obj[1];
    						}
    					});
    				}
    			}).catch(function (error) {});
    		}
    	}
    	
    	$scope.transitionToArchiveArchiveType = function(type, idSelected){
    		if($scope.active == 'all'){
				$scope.classificationSelected = undefined;
    		}else if($scope.active == 'classification'){
				$scope.classificationSelected = idSelected;
    		}
    		addRouterStateTabs();
    		initializeSelected();
    		$scope.tableDefinition.reloadInternalData(true, true);
    	}
    	
    	$scope.sendArchiveDefinitive = function(){
    		if($scope.archiveSelectedRows && $scope.archiveSelectedRows.length > 0){
    			DialogsFactory.confirm('global.archive.archive.archiveSelecteds', 'global.archive.archive.archiveSelectedsBody').then(function (data) {
    				TramFactory.archiveDefinitive($scope.archiveSelectedRows).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.archive.archiveDateError", "global.archive.archive.archiveGeneralError");
    					$scope.tableDefinition.reloadInternalData(true, true);
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.archive.archiveSelectedError'));
                    }); 
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.archive.archiveSelectedsNoSelected'));
    		}
    	}    	
    	$scope.archiveSelectAllFunction = function(){
    		if($scope.archiveSelectAll){
        		TramFactory.getAllIds('archiveArchive', getFilterCall(), getFilterCallAux()).then(function(data) {
        			$scope.archiveSelectedRows.length = 0;
                	if(data && data.length > 0) {
                		_.forEach(data, function(dataId){
                			$scope.archiveSelectedRows.push(dataId);
                			if(!$scope.archiveRowSelected){
                				$scope.archiveRowSelected = {};
                			}
                			$scope.archiveRowSelected[dataId] = true;
                		});
                    }
                	addRouterState();
                }).catch(function (error) {
                	$scope.archiveSelectAll = false;
                });
        	}else{
        		$scope.archiveSelectedRows.length = 0;
            	if($scope.archiveRowSelected){
        			var keys = Object.keys($scope.archiveRowSelected);
                    _.forEach(keys, function(key){
                    	$scope.archiveRowSelected[key] = false;
                    });
        		}
            	addRouterState();
        	}
        }
    	
    	$scope.archiveSelectOne = function(id){
            var indexSelected = $linq($scope.archiveSelectedRows).indexOf(" x => x == " + id);

            if (indexSelected != -1) {
            	$scope.archiveSelectedRows.splice(indexSelected, 1);
            } else {
            	if($scope.tableDefinition.list){
            		var obj = $linq($scope.tableDefinition.list).firstOrDefault(undefined, "x => x.id == "+id);
					if(obj){
						$scope.archiveSelectedRows.push(obj.id);
					}
            	}
            }
            if($scope.archiveSelectedRows && $scope.tableDefinition && $scope.tableDefinition.totalRecords && $scope.tableDefinition.totalRecords == $scope.archiveSelectedRows.length){
            	$scope.archiveSelectAll = true;
            }else{
            	$scope.archiveSelectAll = false;
            }
            addRouterState();
    	} 
    	var columnsAux = [
    		{ id: 'id', column: new ArchiveSelectAllColumn($filter, 'archiveSelectAll', 'archiveSelectAllFunction', 'archiveSelectOne', 'archiveRowSelected', true, function(data){return true}), sortable: false},
    		{ id: 'dossierResponsibleProfiles', column: new DossierResponsibleProfileColumn($filter, 'global.literals.responsibleProfiles', $scope.languageColumn), sortable: false},
    		{ id: 'dossierNumber', column: new TextColumn($filter, 'global.literals.code') },
    		{ id: 'procedure.'+$scope.languageColumn, column:  new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn)},
    		{ id: 'subject', column: new TextColumn($filter, 'global.literals.subject') },
    		{ id: 'archiveDate', column:  new DateColumn($filter, 'global.archive.archive.archiveDate') },
    		{ id: 'archiveDefinitiveExpireDate', column:  new DateColumn($filter, 'global.archive.archive.archiveDefinitiveExpireDate') },
    		{ id: 'archiveDefinitiveDate', column:  new DateColumn($filter, 'global.archive.archive.archiveDefinitiveDate') }
    	];
    	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
    		columnsAux.push({ id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
    				new ActionButton('global.literals.see', 'viewElement([data], [archiveDefinitiveDate])', 'fa-eye', false, undefined, undefined, ['archiveDefinitiveDate']), 
    				[ 
    					new ActionButton('global.archive.archive.archiveSelected','archiveArchive([data])', undefined, undefined,function(data, type, full, meta){
    						if(full && !full.archiveDefinitiveDate && full.archiveDefinitiveExpireDate && new Date() >= new Date(full.archiveDefinitiveExpireDate)){
    							return true;
    						}else{
    							return false;
    						}
    					})
    					]),sortable: false }
    		);
    	}else{
    		columnsAux.push({ id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'),[]),sortable: false });
    	}
    	
    	var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
        	delete x['userProfiles'];
        	delete x['ficticio'];
        });
        
    	$scope.filter = [
    		{ id: 'responsibleTramitProfile', type: 'select-multiple', order: 0, label: 'global.literals.responsibleProfiles',  dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','responsibleTramitProfile', allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','responsibleTramitProfile')},
    		{ id: 'dossierNumber', type: 'text', order: 1, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','dossierNumber')},
    		{ id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','procedure'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','procedure')},
    		{ id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','subject')},
    		{ id: 'archiveDate', type: 'dateRange', order: 4, label: 'global.archive.archive.archiveDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','archiveDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','archiveDate') },
    		{ id: 'archiveDefinitiveExpireDate', type: 'dateRange', order: 5, label: 'global.archive.archive.archiveDefinitiveExpireDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','archiveDefinitiveExpireDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','archiveDefinitiveExpireDate') },
    		{ id: 'archiveDefinitiveDate', type: 'dateRange', order: 6, label: 'global.archive.archive.archiveDefinitiveDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','archiveDefinitiveDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','archiveDefinitiveDate') }
    	];
    	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
    		$scope.filter.push({ id: 'typeSelect', type: 'select', order: 0, label: 'global.archive.archive.typeSelect', dataType: 'LOCAL', data: angular.copy(GlobalDataFactory.archiveSelectType), addAll: false, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','typeSelect',GlobalDataFactory.archiveSelectType,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','typeSelect')});
    	}
		if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.filter);
			}
		}else{
			var routeState = RouteStateFactory.getRouteState($state.current);
		    if(routeState) {
		        $scope.filter = routeState.state;
		    }
	        var routeStateColumnFilter = RouteStateFactory.getRouteState($state.current,'ColumnsFilter');
	        if(routeStateColumnFilter) {
	            if($rootScope.previousState && ($rootScope.previousState.name == 'annexa.archive.archive.see' || $rootScope.previousState.name == 'annexa.archive.archive.edit')){
		        	var routerAux = routeStateColumnFilter.state;
		            $scope.archiveSelectedRows = ((routerAux && routerAux.archiveSelectedRows)?routerAux.archiveSelectedRows:[]);
		    		$scope.archiveRowSelected = ((routerAux && routerAux.archiveRowSelected)?routerAux.archiveRowSelected:{});
		    		$scope.archiveSelectAll = ((routerAux && routerAux.archiveSelectAll != undefined)?routerAux.archiveSelectAll:false);
	            }else{
	            	addRouterState();
	            }
	        }else{
	        	 addRouterState();
	        }
		}
        
        var getFilterCall = function() {
        	return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function () {
        	var filter = TableFilterFactory.getFilterCallAux($scope.filter, true);
        	filter.filterOrigin = 'archiveArchive';
        	if($scope.classificationSelected){
        		filter.classification = $scope.classificationSelected;
        	}
        	return filter;
        }
        
        $scope.viewElement = function(id, archiveDefinitiveDate) {
        	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
        		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'){
        			$state.transitionTo('annexa.archive.archive.see', {dossier: id});
	        	}else if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
	        		if(archiveDefinitiveDate){
	        			$state.transitionTo('annexa.archive.archive.see', {dossier: id});
	        		}else{
	        			$state.transitionTo('annexa.archive.archive.edit', {dossier: id});        			
	        		}
	        	}
        	}
        };
        
        $scope.archiveArchive = function(id) {
        	if(id){
    			DialogsFactory.confirm('global.archive.archive.archiveSelected', 'global.archive.archive.archiveSelectedBody').then(function (data) {
    				TramFactory.archiveDefinitive([id]).then(function(data) {
    					initializeSelected();
    					$scope.showError(data, "global.archive.archive.archiveDateError", "global.archive.archive.archiveGeneralError");
    					$scope.tableDefinition.reloadInternalData(true, true);
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.archive.archive.closeSelectedError'));
                    });
               }).catch(function (data) {
    	       });
    		}else{
    			DialogsFactory.error($filter('translate')('global.archive.archive.archiveSelectedsNoSelected'));
    		}
        };
   	
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_archive_archive', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_archive_archive', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossierNumber', sort: [[2,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
                id: 'tableArchiveArchive',
                origin: 'tram',
                objectType: 'Dossier',
                callOrigin: 'archiveArchive',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: getFilterCall(),
                filterCallAux: getFilterCallAux(),
                filterCallFunc: getFilterCall,
                filterCallAuxFunc: getFilterCallAux,
                columns: columns,
                containerScope: $scope,
				notAddExcel: true
            };
        
        $scope.$on('tableFilterSubmitChange',function(event, args){
        	if(args && args.id == $scope.tableDefinition.id){
        		initializeSelected();
        	}
        });
        
        $scope.exportDossier = function() {
        	if($scope.archiveSelectedRows && $scope.archiveSelectedRows.length > 0){
        		var send = {};
        		var dossiers = [];
        		angular.forEach($scope.archiveSelectedRows, function (value) {
                    if (value) {
                    	dossiers.push({id:value});
                    }
                });
                dossiers = $linq(dossiers).orderBy("x => x.id").toArray();
                send.dossiers = JSOG.encode(dossiers);
                send.languageColumn = $scope.languageColumn;
                send.saveEniExp = false;
                $http({
                    url: './api/tram/exportDossier',
                    method: 'POST',
                    data: send,
                    headers: {
                        accept: 'application/zip'
                    },
                    responseType: 'arraybuffer',
                    cache: false
                }).then(function (data, status, headers) {
                	$scope.exportDossierCallServer(data,status,headers);
                }).catch(function (error) {
                    DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'foliate', error.data)));
                });
    		}else{
    			DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'foliate', {message: 'Not found documents'})));
    		}
        }

		$scope.exportListArchive = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}


    }]);