/**
 * Created by osirvent on 30/06/2016.
 */
angular
    .module('annexaApp')
    .controller('TramController', ['$scope', '$rootScope', 'HeaderService', '$state', 'Language', 'RestService', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'TableFilter', 'BoxFactory', 'DccumentsFactory', '$filter', 'TramFactory', 'GlobalDataFactory', function ($scope, $rootScope, HeaderService, $state, Language, RestService, AnnexaFormlyFactory, AnnexaModalFactory, TableFilter, BoxFactory, DccumentsFactory, $filter, TramFactory, GlobalDataFactory) {
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.tram.start' || message.state.name == 'annexa.tram.pending' || message.state.name == 'annexa.tram.view' || message.state.name == 'annexa.tram.responsable' || message.state.name == 'annexa.tram.dossierRightAccess') {
                
            	$rootScope.headButtons = [];
            	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#dataTableInput', 'fa fa-download', 'global.tram.literals.export_dossier', undefined, 'exportDossier'));
            	
            	$rootScope.subHeadButtons = [];

                $rootScope.subHeadButtons.push(new HeadButtonSearch('#tableFilter'));

                if(message.state.name == 'annexa.tram.pending' && $rootScope.esetMode) {
                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#dataTableInput','fa-plus fa-fw','global.literals.initExp',undefined,'initExp'))
                }
				$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#dataTableInput', 'fa fa-download', 'global.literals.export', undefined, 'exportList'));

                $rootScope.subHeadTabs = [
                    {
                        state: 'annexa.tram.pending',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.pending' ? 'active' : ''),
                        name: 'global.tram.tabs.pending.title',
                        permissions: 'process_dossier'
                    },
                    {
                        state: 'annexa.tram.responsable',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.responsable' ? 'active' : ''),
                        name: 'global.tram.tabs.responsable.title',
                        permissions: ['process_dossier', 'view_dossier', 'see_all_dossier']
                    },
                    {
                        state: 'annexa.tram.view',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.view' ? 'active' : ''),
                        name: 'global.tram.tabs.view.title',
                        permissions: ['process_dossier', 'view_dossier', 'see_all_dossier']
                    },
                    {
                        state: 'annexa.tram.dossierRightAccess',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.dossierRightAccess' ? 'active' : ''),
                        name: 'global.tram.tabs.dossierRightAccess.title',
                        permissions: ['dossier_right_access']
                    }
                ];

                if(!$rootScope.esetMode) {
                    $rootScope.subHeadTabs.push({
                        state: 'annexa.tram.start',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.start' ? 'active' : ''),
                        name: 'global.tram.tabs.start.title',
                        permissions: ['start_dossier']
                    });
                }
            }
        });

        HeaderService.changeState($state.current);
        
        $scope.languageColumn = Language.getActiveColumn();
        $scope.showLongAddressLanguage = $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value;
        $scope.informationLanguageColumn = "";
        if($scope.languageColumn) {
            $scope.informationLanguageColumn = "informationLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);
        }

        //region Filtre pestanya documents

        $scope.filterTabDocuments = [
            { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
            { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
            { id: 'type', type: 'select', order: 2, label: 'global.literals.docType', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: $scope.languageColumn },
            { id: 'transactionType', type: 'select', order: 3, label: 'global.tram.literals.transaction_type', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: $scope.languageColumn }
        ]

        //endregion

        //region Taula pestanya documents

        var docStatusTitle = function() {
            var content = '';

            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown" title="{{\'global.literals.showOptions\'}}">';
            content += '            <span class="fa-stack" aria-hidden="true">';
            content += '                <i class="fa fa-{{headerIconDocStatus(true)}} text-{{headerIconDocStatus(false)}} text-lg fa-stack-1x"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="status in statuses | orderBy:\'orderStatus\'" ng-click="filterData.status = (!status.id || status.id == 0 ?  \'\' : status.id); tableDefinition.reloadInternalData(false);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{status.icon}} text-{{status.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="status.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="global.documentState.{{status.name}}" ng-if="status.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }

        var docStatusRender = function(data, type, full, meta) {
            var content = '';

            if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
                var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                if(status) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
                    content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }

        var actionsTitle = function () {
            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
        }

        actionsRender = function (data, type, full, meta) {
            return '<annexa-document-actions-button dossier-transaction-document="tableObjects[' + full.id + ']" list="true"></annexa-document-actions-button>';
        };

        $scope.columnsTabDocuments = [
            { id: 'document.publicDocument', column: new BooleanColumn($filter, 'global.documentState.public', 'public') },
            { id: 'document.docStatus', width: '53px', title: docStatusTitle(), render: docStatusRender, sortable: false },
            { id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.document.id; if(full.document.code) { code = full.document.code.substr((full.code.indexOf(full.document.id) - 2), full.document.id.toString().length + 2); } code;') },
            { id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.name', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var name = full.document.name; name;') },
            { id: 'dossierTransaction.transactionType.' + $scope.languageColumn, width: '140px', column: new DatabaseTranslatedColumn($filter, 'global.tram.literals.transaction_type', $scope.languageColumn) },
            { id: 'dossierTransaction.' + $scope.languageColumn, title: $filter('translate')('global.literals.transaction') },
            { id: 'actions', columnName: 'id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender }
        ]

        //endregion
        
        $scope.exportDossier = function () {
        	TramFactory.exportDossier();
        };
        
    }])
    .controller('TramStartController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory','HelperService', 'RestService', 'DialogsFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory,HelperService, RestService, DialogsFactory) {
        $scope.tfilter = [
            { id: 'family', type: 'select-tree', order: 0, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('tramitation','tableProcedure','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableProcedure','family')},
            { id: 'id', type: 'text', order: 1, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tramitation','tableProcedure','id'), negated:HelperService.getPrefilterNegated('tramitation','tableProcedure','id')},
            { id: 'acronym', type: 'text', order: 2, label: 'global.literals.acronym', preFilter:HelperService.getPrefilter('tramitation','tableProcedure','acronym'), negated:HelperService.getPrefilterNegated('tramitation','tableProcedure','acronym')},
            { id: Language.getActiveColumn(), type: 'text', order: 3, label: 'global.literals.procedure', preFilter:HelperService.getPrefilter('tramitation','tableProcedure','language'), negated:HelperService.getPrefilterNegated('tramitation','tableProcedure','language')}
        ];
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }
        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn };

        var getFilterCall = function() {
            return TableFilterFactory.getFilterCall($scope.tfilter);
        }

        var getFilterCallAux = function () {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.applyPermissions = true;
            return filterCallAux;
        }
        var ProcedureActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.tram.tabs.start.title','annexa.tram.newdossier({ procedure: \'[data]\' })',undefined,true));

        var columnsAux = [
            { id: 'family.' + Language.getActiveColumn(), column: new DatabaseTranslatedColumn($filter, 'global.literals.family', Language.getActiveColumn())},
            { id: 'id',  title: $filter('translate')('global.literals.code')},
            { id: 'acronym', title: $filter('translate')('global.literals.acronym')},
            { id: Language.getActiveColumn(), column: new DatabaseTranslatedColumn($filter, 'global.literals.procedure', Language.getActiveColumn())},
            { id: 'actions', columnName: 'id', width: '150px', className: 'text-center', column: ProcedureActionsColumn,  sortable: false}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_start', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_start', columnsAux);
        $scope.tableOrderProperties = {sortName: 'family', sort: [[0, 'asc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }
        $scope.tableDefinition = {
            id: 'tableProcedure',
            origin: 'tram',
            objectType: 'Procedure',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope,
			notAddExcel: true
        };
		$scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}
        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }


    }])
    .controller('TramPendingController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramNewFactory', '$stateParams', 'HeaderService', '$state', 'RegFactory', 'RegModals', 'CommonService', 'AnnexaFormlyFactory', 'TableFilterFactory', 'RouteStateFactory', 'GlobalDataFactory', 'DialogsFactory', 'RestService', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramNewFactory, $stateParams, HeaderService, $state, RegFactory, RegModals, CommonService, AnnexaFormlyFactory, TableFilterFactory, RouteStateFactory, GlobalDataFactory, DialogsFactory, RestService) {
        var expiredStates = [
            { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
            { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
        ];
        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, favorite: '', langColumn: $scope.languageColumn };
        var routeStateColumnsFilter = RouteStateFactory.getRouteState($state.current, 'ColumnsFilter');
        if(routeStateColumnsFilter) {
            $scope.filterData = routeStateColumnsFilter.state;
        }

        var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
        	delete x['userProfiles'];
        	delete x['ficticio'];
        });
        if($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles){
        	var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile").toArray();
        }
        
        $scope.tfilter = [];
        $scope.tfilter.push({ id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','dossierNumber')});
        $scope.tfilter.push({ id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','family')});
        $scope.tfilter.push({ id: 'procedure', type: 'select', order: 2, label: 'global.literals.procedure', dataType: 'LOCAL', data: GlobalDataFactory.procedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','procedure',GlobalDataFactory.procedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','procedure')});
        $scope.tfilter.push({ id: 'procedureClassification', type: 'select', order: 3, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.procedureClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','procedureClassification',GlobalDataFactory.procedureClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','procedureClassification')});
        $scope.tfilter.push({ id: 'subject', type: 'text', order: 4, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','subject')});
        $scope.tfilter.push({ id: 'thirds', type: 'text', order: 5, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','thirds')});
        $scope.tfilter.push({ id: Language.getActiveColumn(), type: 'text', order: 6, label: 'global.literals.transaction', preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','language'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','language')});
        $scope.tfilter.push({ id: 'transactionType', type: 'select', order: 6, label: 'global.literals.transactionType', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','transactionType',GlobalDataFactory.transactiontypes,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','transactionType')});
        $scope.tfilter.push({ id: 'custom_fields_search', type: 'text', order: 7, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','custom_fields_search')});
        $scope.tfilter.push({ id: 'dates', type: 'dateRange', order: 8, label: 'global.tram.literals.startDateTram', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','dates'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','dates')});
        $scope.tfilter.push({ id: 'creator', type: 'text', order: 9, label: 'global.tram.literals.tramCreator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','creator')});
        $scope.tfilter.push({ id: 'dossierResponsibleUser', type: 'text', order: 10, label: 'global.tram.literals.dossierResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','dossierResponsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','dossierResponsibleUser')});
        if ($rootScope.esetMode) {
        	$scope.tfilter.push({ id: 'responsibleUser', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true, model:(($rootScope.LoggedUser)?($rootScope.LoggedUser.name+" "+$rootScope.LoggedUser.surename1+(($rootScope.LoggedUser.surename2)?' '+$rootScope.LoggedUser.surename2:'')):undefined), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','responsibleUser')});
        } else {        	
        	$scope.tfilter.push({ id: 'responsibleUser', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','responsibleUser')});
        }
        $scope.tfilter.push({ id: 'responsibleTramitProfile', type: 'select-multiple', order: 11, label: 'global.literals.responsibleTramitProfile', dataType: 'LOCAL', data: userProfiles, addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','responsibleTramitProfile',userProfiles,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','responsibleTramitProfile')});
        $scope.tfilter.push({ id: 'register_entry', type: 'text', order: 12, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','register_entry')});
        $scope.tfilter.push({ id: 'expired', type: 'select', order: 13, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy(expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','expired',expiredStates,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','expired')});
        $scope.tfilter.push({ id: 'address', type: 'text', order: 14, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','address')});
        $scope.tfilter.push({ id: 'tramitationPhase', type: 'select-multiple', order: 15, label: 'global.literals.tramitationPhase', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.tramitationPhase,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','tramitationPhase', GlobalDataFactory.tramitationPhase, 'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','tramitationPhase')});
        $scope.tfilter.push({ id: 'archiveClassification', type: 'select-tree', order: 16, label: 'global.literals.classificationBox', dataType: 'LOCAL', data: GlobalDataFactory.archiveClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','archiveClassification', GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','archiveClassification') });
        if($rootScope.app.configuration.tram_classification_dossier.show) {
        	$scope.tfilter.push({ id: 'tramClassification', type: 'select', order: 17, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','tramClassification')});
        }
		if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.tfilter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.tfilter);
			}
		}else{
			var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.tfilter = routeState.state;
	        }
		}
        var prefilter = undefined;
        if($stateParams.type){
            prefilter = {};
            var filterExpired = $linq($scope.tfilter).where("x => x.id == 'expired'").toArray();
            if($stateParams.type == 'expired' && filterExpired){
                filterExpired[0].preFilter = 'expired';
                prefilter.expired = {value:'expired'};
            }else if($stateParams.type == 'favorit'){
                $scope.filterData.favorite = $stateParams.type;
            }
        }

        $scope.FavoritesColumnRenderFunction = function (data) {
            if(data) {
                var users = $linq(data)
                    .where("x => x.user.id == " + $rootScope.LoggedUser.id)
                    .toArray();
                if (users.length != 0) {
                    return 'favorit';
                } else {
                    return 'nofavorit';
                }
            }
            return '';
        };

        $scope.FavoritesColumnModificar = function(id) {
            TramNewFactory.modifyFavorite(id, $rootScope.LoggedUser.id).then(function(data){
                $scope.tableDefinition.reloadInternalData(false,true);
            }).catch(function(error){
                $scope.tableDefinition.reloadInternalData(false, true);
            });
        }

        $scope.FavoritesColumn = new IconSearchColumn(
            'dossier.favoriteUsers',
            '<iconsearchcolumn definition="FavoritesColumn" filterModel="filterData" fieldName="favorite"/>',
            '5%',
            new IconSearchRender($scope, 'FavoritesColumn'),
            false,
            true,
            [
                new IconSearchOption('favorit','favorit', 'fa-star text-warn text-lg', 'fa-star text-warn text-lg', 'global.literals.favorite'),
                new IconSearchOption('nofavorit','nofavorit', 'fa-star-o text-lg', 'fa-star-o text-lg', 'global.literals.noFavorite'),
            ],
            'tableDefinition',
            $scope.FavoritesColumnRenderFunction,
            'FavoritesColumnModificar'
        );

        var literalDays = !$rootScope.esetMode ? 'global.literals.days' : 'global.literals.dossierDays';

        var DaysColumn = new RemaingDaysColumn($filter, literalDays, HelperService);
        var TransactionDaysColumn = new RemaingDaysColumn($filter, 'global.literals.transactionDays', HelperService);
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn);
        var TransactionColumn = new TransactionNameColumn($filter, 'global.literals.transaction', $scope.languageColumn);
        var SubjectColumn = new DossierSubjectColumn($filter, 'global.literals.subject', $scope.languageColumn);
       	var transactionCreatorColumn = new TransactionCreatorColumn($filter, 'global.tram.literals.tramCreator');
       	var dossierResponsibleColumn = new TransactionDossierResponsibleColumn($filter, 'global.tram.literals.dossierResponsible');
        var transactionResponsibleColumn = new TransactionResponsibleColumn($filter, 'global.tram.literals.tramResponsible');
        var transactionResponsibleProfileColumn = new TransactionResponsibleProfileColumn($filter, 'global.literals.responsibleTramitProfile', $scope.languageColumn);
        var TranCounterColumn = new TransactionsCounterColumn();
        var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
        var RelatedColumn = new RelatedDossiersColumn();
		var addressColumn = new AddressesColumn($filter('translate')('global.territory.list.address'), $scope.languageColumn, $scope.showLongAddressLanguage);
        var DossierTransactionActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','openDossier([data], [data2], [dossier.bigDossier])',undefined,false,undefined, undefined, ['dossier.bigDossier']), undefined, 'id');
        var PrincipalColumn = new PrincipalThirdColumn($filter,'global.literals.principalDest');
        var TransactionType = new DatabaseTranslatedColumn($filter, 'global.tram.literals.transaction_type', $scope.languageColumn);
		var profilesColumn = new ProfilesColumn($filter('translate')('global.literals.responsiblesDossierProfiles'), $scope.languageColumn);

        var DossierNumberColumn = new LinkColumn($filter, 'global.literals.code', 'viewDossier', 'dossier.id' );

        var columnsAux = [];
        if(!$rootScope.esetMode) {
            columnsAux = [
                { id: 'dossier.favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender()},
                { id: 'transactionRemainingDays', width: '40px', column: TransactionDaysColumn },
                { id: 'dossier.dossierNumber', width: '140px', title:$filter('translate')('global.literals.code')},
                { id: 'dossier.procedure.' + ProcedureColumn.getColumn(), width: '140px', title:  ProcedureColumn.getTitle() },
                { id: TransactionColumn.getColumn(), width: '150px', title: TransactionColumn.getTitle(),  render: TransactionColumn.getRender },
                { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
                { id: 'dossier.subject', width: '150px', title: SubjectColumn.getTitle(),  render: SubjectColumn.getRender },
                { id: 'dossier.principalThird', width: '200px', column:  PrincipalColumn, sortable: false },
                { id: 'startDate', width: '50px', column:  transactionCreatorColumn },
                { id: 'dossier.responsibleUser.completeName', width: '50px', column: dossierResponsibleColumn },
                { id: 'dossier.dossierResponsibleProfiles', width: '50px', column: profilesColumn, sortable: false },
                { id: 'responsibleUser.completeName', width: '50px', column:  transactionResponsibleColumn },
                { id: 'processProfiles.profile.' + $scope.languageColumn, width: '50px', column:  transactionResponsibleProfileColumn },
                { id: 'dossier.relatedDossiersNumber', width: '40px', render: RelatedColumn.getRender, sortable: false },
				{ id: 'dossier.addresses', column: addressColumn, sortable: false },
                { id: 'actions', columnName: 'dossier.id', width: '100px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
            ];
        } else {
            columnsAux = [
                { id: 'dossier.favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender()},
                { id: 'transactionRemainingDays', width: '40px', column: TransactionDaysColumn },
                { id: 'dossier.dossierNumber', width: '140px', title:$filter('translate')('global.literals.code')},
                { id: 'transactionType.' + TransactionType.getColumn(), width: '140px', title: TransactionType.getTitle()},
                { id: 'dossier.procedure.' + ProcedureColumn.getColumn(), width: '140px', title:  ProcedureColumn.getTitle() },
                { id: TransactionColumn.getColumn(), width: '150px', title: TransactionColumn.getTitle(),  render: TransactionColumn.getRender },
                { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
                { id: 'dossier.subject', width: '150px', title: SubjectColumn.getTitle(),  render: SubjectColumn.getRender },
                { id: 'dossier.principalThird', width: '200px', column:  PrincipalColumn, sortable: false },
                { id: 'startDate', width: '50px', column:  transactionCreatorColumn },
                { id: 'dossier.responsibleUser.completeName', width: '50px', column: dossierResponsibleColumn },
                { id: 'responsibleUser.completeName', width: '50px', column:  transactionResponsibleColumn },
                { id: 'processProfiles.profile.' + $scope.languageColumn, width: '50px', column:  transactionResponsibleProfileColumn },
                { id: 'dossier.relatedDossiersNumber', width: '40px', render: RelatedColumn.getRender, sortable: false },
                { id: 'dossier.addresses', column: addressColumn, sortable: false },
                { id: 'actions', columnName: 'dossier.id', width: '100px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
            ];
        }

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_pending', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_pending', columnsAux);
        $scope.tableOrderProperties = {sortName: 'startDate', sort: [[1,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            filterCall.actual = true;
            filterCall.dossier = {closed:false};
            return filterCall;
        }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.favorite = $scope.filterData.favorite;
            if(filterCallAux.expired){
                if(filterCallAux.expired.id == 'expired'){
                    filterCallAux.expired = true;
                }else if(filterCallAux.expired.id == 'noExpired'){
                    filterCallAux.expired = false;
                }else{
                    filterCallAux.expired = undefined;
                }
            }
            if(filterCallAux.procedure) {
				filterCallAux.procedure = {id:filterCallAux.procedure.id};
			}
            if(filterCallAux.transactionType) {
				filterCallAux.transactionType = {id:filterCallAux.transactionType.id};
			}
            if(filterCallAux.procedureClassification) {
				filterCallAux.procedureClassification = {id:filterCallAux.procedureClassification.id};
			}
            filterCallAux.query = false;
            return filterCallAux;
        }

        $scope.tableDefinition = {
            id: 'tableDossierTransactionView',
            origin: 'tram',
            objectType: 'DossierTransaction',
			callOrigin: 'annexa-gee-local',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope,
			notAddExcel: true
        };

        $scope.viewDossier = function (id) {
            $state.transitionTo('annexa.tram.pending.viewdossier', { "dossier" : id });
        }
        
        $scope.openDossier = function (id, idDt, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	$state.transitionTo('annexa.tram.pending.viewdossier', ({ dossier: id, dossierTransaction : idDt}));	            
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		$state.transitionTo('annexa.tram.pending.viewdossier', ({ dossier: id, dossierTransaction : idDt}));
        	}
        }

        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }

        $scope.initExp = function() {
            RegFactory.findFamiliesByRegisterClassification(undefined)
                .then(function(data) {
                    var familiesProcedure = JSOG.decode(data.data);
                    familiesProcedure = $linq(familiesProcedure).orderBy("x => x." + $scope.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                    var procedures = $linq(GlobalDataFactory.procedures).where("x => x.active == true").orderBy("x => x." + $scope.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                    var deletedProcedures = [];
                    _.forEach(procedures, function(value){
                        var profiles = $linq(value.procedureStartProfiles).intersect($rootScope.LoggedUser.userProfiles, "(x,y) => x.profile.id == y.profile.id").toArray();
                        if(!profiles || profiles.length == 0){
                            deletedProcedures.push(value);
                        }
                    });
                    procedures = $linq(procedures).except(deletedProcedures, "(x,y) => x.id == y.id").toArray();
                    
                    var modal = RegModals.initExpRegisterSelect;
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = { familyProcedure: {}, isPublic_true: true};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = { readOnly: false};
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.labelProp = Language.getActiveColumn();
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = CommonService.getTreeData(familiesProcedure, Language.getActiveColumn());
                    modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.entryClassification = undefined;
                    modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.procedures = procedures;
                    AnnexaFormlyFactory.showModal('modalGoToTram', modal, $scope.goToTram, false);

                })
        }
        $scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}
        $scope.goToTram = function () {
            $state.transitionTo('annexa.tram.newdossier', { "procedure": this.annexaFormly.model.modal_body.procedure });
            this.close();
        }
    }])
    .controller('TramViewController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramNewFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', 'RestService', 'DialogsFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramNewFactory,  $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory, RestService, DialogsFactory) {
        var dossierStatuses = [
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') },
            { id: 'CLOSED', name: $filter('translate')('CLOSED') } 
        ];

        var tramitationEstates = [
            { id: 'TRAMITATION', name: $filter('translate')('global.literals.tramParticipated') },
            { id: 'SEE', name: $filter('translate')('global.literals.tramPendingSee') }
        ];
        
        var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
            delete x['userProfiles'];
            delete x['ficticio'];
        });

        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, favorite: '', langColumn: $scope.languageColumn, query: true };

        $scope.tfilter = [
            { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dossierNumber')},
            { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','family')},
            { id: 'procedure', type: 'select', order: 2, label: 'global.literals.procedure', dataType: 'LOCAL', data: GlobalDataFactory.procedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','procedure',GlobalDataFactory.procedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','procedure')},
            { id: 'procedureClassification', type: 'select', order: 3, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.procedureClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter: HelperService.getPrefilter('tramitation','tableDossierView','procedureClassification', GlobalDataFactory.procedureClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','procedureClassification')},
            { id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','subject')},
            { id: 'thirds', type: 'text', order: 3, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','thirds')},
            { id: 'transaction', type: 'text', order: 5, label: 'global.literals.transaction', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','transaction'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','transaction')},
            { id: 'transactionType', type: 'select', order: 6, label: 'global.literals.transactionType', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','transactionType', GlobalDataFactory.transactiontypes, 'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','transactionType')},
            { id: 'custom_fields_search', type: 'text', order: 6, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','custom_fields_search')},
            { id: 'dates', type: 'dateRange', order: 7, label: 'global.delegate.startDate', callAux: true , preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dates'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dates')},
            { id: 'dossierResponsibleUser', type: 'text', order: 8, label: 'global.tram.literals.dossierResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dossierResponsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dossierResponsibleUser')},
            (
                ($rootScope.esetMode)?
                { id: 'responsibleUser', type: 'text', order: 9, label: 'global.literals.responsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','responsibleUser')}
                    :
                { id: 'creator', type: 'text', order: 9, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','creator')}
            ),
            { id: 'register_entry', type: 'text', order: 10, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','register_entry')},
            { id: 'dossierStatus', type: 'select', order: 11, label: 'global.literals.state', dataType: 'LOCAL', data: angular.copy(dossierStatuses), addAll: true, nameProperty: 'name', callAux: true , preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dossierStatus',dossierStatuses,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dossierStatus')},
            { id: 'responsibleDossierProfile', type: 'select-multiple', order: 11, label: 'global.literals.responsiblesDossierProfiles', dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','responsibleDossierProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','responsibleDossierProfile')},
            { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','address') },
			{ id: 'show_archived', type: 'checkbox', order: 13, label: 'global.archive.archiveds', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','show_archived')},
			{ id: 'archiveClassification', type: 'select-tree', order: 14, label: 'global.literals.classificationBox', dataType: 'LOCAL', data: GlobalDataFactory.archiveClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','archiveClassification', GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','archiveClassification')}
		];
        
        if($rootScope.app.configuration.tram_classification_dossier.show) {
        	$scope.tfilter.push({ id: 'tramClassification', type: 'select', order: 15, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','tramClassification')});
        }
        
        if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.tfilter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.tfilter);
			}
		}else{
			var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.tfilter = routeState.state;
	        }
		}
        var prefilter = undefined;
        if($stateParams.type){
            prefilter = {};
            var filterDossierStatus = $linq($scope.tfilter).where("x => x.id == 'dossierStatus'").toArray();
            if($stateParams.type == 'tramitation'){
            }else if($stateParams.type == 'finally' && filterDossierStatus){
                filterDossierStatus[0].preFilter = 'FINISHED';
                prefilter.dossierStatus = {value:'FINISHED'};
            }else if($stateParams.type == 'participated'){
            }else if($stateParams.type == 'favorit'){
                $scope.filterData.favorite = $stateParams.type;
            }
        }
        
        $scope.FavoritesColumnRenderFunction = function (data) {
            if(data) {
                var users = $linq(data)
                    .where("x => x.user.id == " + $rootScope.LoggedUser.id)
                    .toArray();
                if (users.length != 0) {
                    return 'favorit';
                } else {
                    return 'nofavorit';
                }
            }
            return '';
        };
        
        $scope.FavoritesColumnModificar = function(id) {
        	TramNewFactory.getDossier(id).then(function(data){
        		var idDossierTransaction = 1;
        		var actualDossierTransaction = $linq(TramNewFactory.Dossier.dossierTransactions)
        			.where("x => x.actual == true").toArray();
        		if(actualDossierTransaction != null && actualDossierTransaction.lenght != null && actualDossierTransaction.lenght != 0){
        			idDossierTransaction = actualDossierTransaction[0].id;
        		} else {
        			idDossierTransaction = TramNewFactory.Dossier.dossierTransactions[0].id;
        		}
        		TramNewFactory.modifyFavorite(idDossierTransaction, $rootScope.LoggedUser.id).then(function(data){
                    $scope.tableDefinition.reloadInternalData(false,true);
                }).catch(function(error){
                    $scope.tableDefinition.reloadInternalData(false, true);
                });
            }).catch(function(error){
                $scope.tableDefinition.reloadInternalData(false, true);
            });
        }
        
        $scope.FavoritesColumn = new IconSearchColumn(
                'favoriteUsers',
                '<iconsearchcolumn definition="FavoritesColumn" filterModel="filterData" fieldName="favorite"/>',
                '5%',
                new IconSearchRender($scope, 'FavoritesColumn'),
                false,
                true,
                [
                    new IconSearchOption('favorit','favorit', 'fa-star text-warn text-lg', 'fa-star text-warn text-lg', 'global.literals.favorite'),
                    new IconSearchOption('nofavorit','nofavorit', 'fa-star-o text-lg', 'fa-star-o text-lg', 'global.literals.noFavorite'),
                ],
                'tableDefinition',
                $scope.FavoritesColumnRenderFunction,
                'FavoritesColumnModificar'
            );
        
        var literalDays = !$rootScope.esetMode ? 'global.literals.days' : 'global.literals.dossierDays';

        var DaysColumn = new RemaingDaysColumn($filter, literalDays, HelperService);
        var StatusColumn = new DossierStatusColumn($filter, 'global.literals.dossier');
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn);
        var TranCounterColumn = new TransactionsCounterColumn();
        var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
        var DossierTransactionActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','openDossier([data], [bigDossier])',undefined,false,function(data, type, full, meta){
        	if(full && full.dossierStatus && _.contains(['ARCHIVED','REJECTED','TRANSFER'], full.dossierStatus)){
        		return false;
        	}else{
        		return true;
        	}
        }, undefined, ['bigDossier']),undefined,'id');
        var PrincipalColumn = new PrincipalThirdColumn($filter,'global.literals.principalDest');
        var CreatedDateColumn = new DateColumn($filter, 'global.literals.creation_date');
       	var dossierResponsibleColumn = new DossierResponsibleColumn($filter, 'global.tram.literals.dossierResponsible');
		var addressColumn = new AddressesColumn($filter('translate')('global.territory.list.address'), $scope.languageColumn, $scope.showLongAddressLanguage);
        var RelatedColumn = new RelatedDossiersColumn();
		var profilesColumn = new ProfilesColumn($filter('translate')('global.literals.responsiblesDossierProfiles'), $scope.languageColumn);
        var SubjectColumn = new DossierSubjectColumn($filter, 'global.literals.subject', $scope.languageColumn);
        
        var DossierTransactionStatusRender = function(data, type, full, meta) {
            var content = '';

            if(data) {
                content = '<i class="text-success fa fa-circle" title="' + $filter('translate')('global.literals.closedMS') + '" aria-hidden="true"></i> ';
            } else {
                content = '<i class="text-warn fa fa-circle" title="' + $filter('translate')('global.literals.openedMS') + '" aria-hidden="true"></i> ';
            }

            return content;
        }

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            return filterCall;
        }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.filterOrigin = 'dossierView';
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.favorite = $scope.filterData.favorite;
            if(filterCallAux.procedure) {
				filterCallAux.procedure = {id:filterCallAux.procedure.id};
			}
            if(filterCallAux.transactionType) {
				filterCallAux.transactionType = {id:filterCallAux.transactionType.id};
			}
            if(filterCallAux.procedureClassification) {
				filterCallAux.procedureClassification = {id:filterCallAux.procedureClassification.id};
			}
            return filterCallAux;
        }

        var columnsAux = [
        	{ id: 'favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender(), sortable: false},
            { id: 'dossierStatus', width: '50px', column: StatusColumn },
            { id: 'remainingDays', width: '40px', column: DaysColumn },
            { id: 'dossierNumber', width: '140px', title: $filter('translate')('global.literals.code') },
            { id: 'procedure.' + ProcedureColumn.getColumn(), width: '140px', title:ProcedureColumn.getTitle()},
            { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
            { id: 'subject', width: '150px', title: SubjectColumn.getTitle(),  render: SubjectColumn.getRender },
            { id: 'principalThird', width: '200px', column: PrincipalColumn, sortable: false },
            { id: 'responsibleUser.name', width: '110px', column: dossierResponsibleColumn},
            { id: 'creatorUser', width: '150px', column: CreatorUserColumn},
            { id: 'createdDate', width: '100px', column:  CreatedDateColumn},
            { id: 'dossierResponsibleProfiles', width: '110px', column: profilesColumn, sortable: false },
            { id: 'relatedDossiersNumber', width: '40px', render: RelatedColumn.getRender, sortable: false },
			{ id: 'addresses',  column: addressColumn, sortable: false },
            { id: 'actions', columnName: 'id', width: '40px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_view', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_view', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossier.dossierNumber', sort: [[7, 'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
            id: 'tableDossierView',
            origin: 'tram',
            objectType: 'Dossier',
            callOrigin: 'annexa-gee-local',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope,
			notAddExcel: true
        };

        $scope.openDossier = function (id, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	$state.transitionTo('annexa.tram.view.viewdossier', ({ dossier: id }));	            
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		$state.transitionTo('annexa.tram.view.viewdossier', ({ dossier: id }));
        	}
        }
		$scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
			
		}
        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }
    }])
    .controller('TramResponsableController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramNewFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', 'RestService', 'DialogsFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramNewFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory, RestService, DialogsFactory) {
        var dossierStatuses = [
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') }
        ];

        var tramitationEstates = [
            { id: 'TRAMITATION', name: $filter('translate')('global.literals.tramParticipated') },
            { id: 'SEE', name: $filter('translate')('global.literals.tramPendingSee') }
        ];
        
        var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
        	delete x['userProfiles'];
        	delete x['ficticio'];
        });

        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, favorite: '', langColumn: $scope.languageColumn, query: true };

        $scope.tfilter = [
            { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','dossierNumber')},
            { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','family')},
            { id: 'procedure', type: 'select', order: 2, label: 'global.literals.procedure', dataType: 'LOCAL', data: GlobalDataFactory.procedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','procedure', GlobalDataFactory.procedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','procedure')},
            { id: 'procedureClassification', type: 'select', order: 3, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.procedureClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','procedureClassification', GlobalDataFactory.procedureClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','procedureClassification')},
            { id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','subject')},
            { id: 'thirds', type: 'text', order: 3, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','thirds')},
            { id: 'transaction', type: 'text', order: 5, label: 'global.literals.transaction', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','transaction'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','transaction')},
            { id: 'transactionType', type: 'select', order: 6, label: 'global.literals.transactionType', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','transactionType', GlobalDataFactory.transactiontypes,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','transactionType')},
            { id: 'custom_fields_search', type: 'text', order: 6, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','custom_fields_search')},
            { id: 'dates', type: 'dateRange', order: 7, label: 'global.delegate.startDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','dates') , negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','dates')},
            (
                ($rootScope.esetMode)?
                { id: 'responsibleUser', type: 'text', order: 8, label: 'global.literals.responsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','responsibleUser')}
                    :
                { id: 'creator', type: 'text', order: 8, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','creator')}
            ),
            { id: 'register_entry', type: 'text', order: 9, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','register_entry')},
            { id: 'responsibleUserTram', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','responsibleUserTram'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','responsibleUserTram')},
            { id: 'responsibleTramitProfile', type: 'select-multiple', order: 11, label: 'global.literals.responsibleTramitProfile', dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','responsibleTramitProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','responsibleTramitProfile')},
            { id: 'address', type: 'text', order: 11, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','address') },
            { id: 'tramitationPhase', type: 'select-multiple', order: 12, label: 'global.literals.tramitationPhase', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.tramitationPhase,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','tramitationPhase',GlobalDataFactory.tramitationPhase,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','tramitationPhase')},
            { id: 'archiveClassification', type: 'select-tree', order: 13, label: 'global.literals.classificationBox', dataType: 'LOCAL', data: GlobalDataFactory.archiveClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','archiveClassification', GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','archiveClassification')}      
        ];
        
        if($rootScope.app.configuration.tram_classification_dossier.show) {
        	$scope.tfilter.push({ id: 'tramClassification', type: 'select', order: 14, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','tramClassification')});
        }
        if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.tfilter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.tfilter);
			}
		}else{
			var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.tfilter = routeState.state;
	        }
		}
        var prefilter = undefined;
        if($stateParams.type){
            prefilter = {};
            var filterDossierStatus = $linq($scope.tfilter).where("x => x.id == 'dossierStatus'").toArray();
            if($stateParams.type == 'tramitation'){
                //$scope.tfilter.tramitation.model = {id:'SEE'};
                //prefilter.tramitation = {value:'SEE'};
            }else if($stateParams.type == 'finally' && filterDossierStatus){
                filterDossierStatus[0].preFilter = 'FINISHED';
                prefilter.dossierStatus = {value:'FINISHED'};
            }else if($stateParams.type == 'participated'){
                //$scope.tfilter.tramitation.model = {id:'TRAMITATION'};
                //prefilter.tramitation = {value:'TRAMITATION'};
            }else if($stateParams.type == 'favorit'){
                $scope.filterData.favorite = $stateParams.type;
            }
        }

        $scope.FavoritesColumnRenderFunction = function (data) {
            if(data) {
                var users = $linq(data)
                    .where("x => x.user.id == " + $rootScope.LoggedUser.id)
                    .toArray();
                if (users.length != 0) {
                    return 'favorit';
                } else {
                    return 'nofavorit';
                }
            }
            return '';
        };
        
        $scope.FavoritesColumnModificar = function(id) {
        	TramNewFactory.getDossier(id).then(function(data){
        		var idDossierTransaction = 1;
        		var actualDossierTransaction = $linq(TramNewFactory.Dossier.dossierTransactions)
        			.where("x => x.actual == true").toArray();
        		if(actualDossierTransaction != null && actualDossierTransaction.lenght != null && actualDossierTransaction.lenght != 0){
        			idDossierTransaction = actualDossierTransaction[0].id;
        		} else {
        			idDossierTransaction = TramNewFactory.Dossier.dossierTransactions[0].id;
        		}
        		TramNewFactory.modifyFavorite(idDossierTransaction, $rootScope.LoggedUser.id).then(function(data){
                    $scope.tableDefinition.reloadInternalData(false,true);
                }).catch(function(error){
                    $scope.tableDefinition.reloadInternalData(false, true);
                });
            }).catch(function(error){
                $scope.tableDefinition.reloadInternalData(false, true);
            });
        }
        
        $scope.FavoritesColumn = new IconSearchColumn(
                'favoriteUsers',
                '<iconsearchcolumn definition="FavoritesColumn" filterModel="filterData" fieldName="favorite"/>',
                '5%',
                new IconSearchRender($scope, 'FavoritesColumn'),
                false,
                true,
                [
                    new IconSearchOption('favorit','favorit', 'fa-star text-warn text-lg', 'fa-star text-warn text-lg', 'global.literals.favorite'),
                    new IconSearchOption('nofavorit','nofavorit', 'fa-star-o text-lg', 'fa-star-o text-lg', 'global.literals.noFavorite'),
                ],
                'tableDefinition',
                $scope.FavoritesColumnRenderFunction,
                'FavoritesColumnModificar'
            );
        
        var literalDays = !$rootScope.esetMode ? 'global.literals.days' : 'global.literals.dossierDays';

        var DaysColumn = new RemaingDaysColumn($filter, literalDays, HelperService);
        var StatusColumn = new DossierStatusColumn($filter, 'global.literals.dossier');
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn);
        var TranCounterColumn = new TransactionsCounterColumn();
        var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
        var DossierTransactionActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','openDossier([data], [bigDossier])',undefined,false,undefined, undefined, ['bigDossier']),undefined,'id');
        var PrincipalColumn = new PrincipalThirdColumn($filter,'global.literals.principalDest');
        var CreatedDateColumn = new DateColumn($filter, 'global.literals.creation_date');
		var addressColumn = new AddressesColumn($filter('translate')('global.territory.list.address'), $scope.languageColumn, $scope.showLongAddressLanguage);
        var SubjectColumn = new DossierSubjectColumn($filter, 'global.literals.subject', $scope.languageColumn);
        var RelatedColumn = new RelatedDossiersColumn();
        
        var DossierTransactionStatusRender = function(data, type, full, meta) {
            var content = '';

            if(data) {
                content = '<i class="text-success fa fa-circle" title="' + $filter('translate')('global.literals.closedMS') + '" aria-hidden="true"></i> ';
            } else {
                content = '<i class="text-warn fa fa-circle" title="' + $filter('translate')('global.literals.openedMS') + '" aria-hidden="true"></i> ';
            }

            return content;
        }

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            return filterCall;
        }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.filterOrigin = 'dossierView';
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.responsibleFilter = true;
            filterCallAux.favorite = $scope.filterData.favorite;
            filterCallAux.dossierStatus = {id:"IN_PROGRESS"};
            if(filterCallAux.procedure) {
				filterCallAux.procedure = {id:filterCallAux.procedure.id};
			}
            if(filterCallAux.transactionType) {
				filterCallAux.transactionType = {id:filterCallAux.transactionType.id};
			}
            if(filterCallAux.procedureClassification) {
				filterCallAux.procedureClassification = {id:filterCallAux.procedureClassification.id};
			}
            return filterCallAux;
        }

        var columnsAux = [
        	{ id: 'favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender(), sortable: false},
            { id: 'dossierStatus', width: '50px', column: StatusColumn },
            { id: 'remainingDays', width: '40px', column: DaysColumn },
            { id: 'dossierNumber', width: '140px', title: $filter('translate')('global.literals.code') },
            { id: 'procedure.' + ProcedureColumn.getColumn(), width: '140px', title:ProcedureColumn.getTitle()},
            { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
            { id: 'subject', width: '150px', title: SubjectColumn.getTitle(),  render: SubjectColumn.getRender },
            { id: 'principalThird', width: '200px', column: PrincipalColumn, sortable: false },
            { id: 'creatorUser', width: '150px', column: CreatorUserColumn},
            { id: 'createdDate', width: '100px', column:  CreatedDateColumn},
            { id: 'relatedDossiersNumber', width: '40px', render: RelatedColumn.getRender, sortable: false },
			{ id: 'addresses', column: addressColumn , sortable: false},
            { id: 'actions', columnName: 'id', width: '40px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_view', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_view', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossier.dossierNumber', sort: [[7, 'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
            id: 'tableDossierResponsible',
            origin: 'tram',
            objectType: 'Dossier',
            callOrigin: 'annexa-gee-local',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope,
			notAddExcel: true
        };
        $scope.openDossier = function (id, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	$state.transitionTo('annexa.tram.responsable.viewdossier', ({ dossier: id }));	            
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		$state.transitionTo('annexa.tram.responsable.viewdossier', ({ dossier: id }));
        	}
        }
		$scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}
        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }
    }])
    .controller('TramDossierRightAccessController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory) {
        var dossierStatuses = [
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') },
            { id: 'CLOSED', name: $filter('translate')('CLOSED') },
            { id: 'TRANSFER', name: $filter('translate')('TRANSFER') },
            { id: 'REJECTED', name: $filter('translate')('REJECTED') },
            { id: 'ARCHIVED', name: $filter('translate')('ARCHIVED') }
        ];

        var tramitationEstates = [
            { id: 'TRAMITATION', name: $filter('translate')('global.literals.tramParticipated') },
            { id: 'SEE', name: $filter('translate')('global.literals.tramPendingSee') }
        ];

        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, favorite: '', langColumn: $scope.languageColumn, query: true };

        $scope.tfilter = [
            { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dossierNumber')},
            { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','family')},
            { id: 'procedure', type: 'select', order: 2, label: 'global.literals.procedure', dataType: 'LOCAL', data: GlobalDataFactory.procedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','procedure',GlobalDataFactory.procedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','procedure')},
            { id: 'procedureClassification', type: 'select', order: 3, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.procedureClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','procedureClassification',GlobalDataFactory.procedureClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','procedureClassification')},
            { id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','subject')},
            { id: 'thirds', type: 'text', order: 3, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','thirds')},
            { id: 'transaction', type: 'text', order: 5, label: 'global.literals.transaction', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','transaction'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','transaction')},
            { id: 'transactionType', type: 'select', order: 6, label: 'global.literals.transactionType', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','transactionType',GlobalDataFactory.transactiontypes,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','transactionType')},
            { id: 'custom_fields_search', type: 'text', order: 6, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','custom_fields_search')},
            { id: 'dates', type: 'dateRange', order: 7, label: 'global.delegate.startDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dates'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dates') },
            { id: 'dossierResponsibleUser', type: 'text', order: 8, label: 'global.tram.literals.dossierResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dossierResponsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dossierResponsibleUser')},
            (
                ($rootScope.esetMode)?
                { id: 'responsibleUser', type: 'text', order: 9, label: 'global.literals.responsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','responsibleUser')}
                    :
                { id: 'creator', type: 'text', order: 9, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','creator')}
            ),
            { id: 'register_entry', type: 'text', order: 10, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','register_entry')},
            { id: 'dossierStatus', type: 'select', order: 11, label: 'global.literals.state', dataType: 'LOCAL', data: angular.copy(dossierStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dossierStatus',dossierStatuses,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dossierStatus') },
            { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','address') },
        ];
        
        if($rootScope.app.configuration.tram_classification_dossier.show) {
        	$scope.tfilter.push({ id: 'tramClassification', type: 'select', order: 13, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','tramClassification')});
        }
        if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.tfilter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.tfilter);
			}
		}else{
			var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.tfilter = routeState.state;
	        }
		}
        
        $scope.FavoritesColumnRenderFunction = function (data) {
            if(data) {
                var users = $linq(data)
                    .where("x => x.user.id == " + $rootScope.LoggedUser.id)
                    .toArray();
                if (users.length != 0) {
                    return 'favorit';
                } else {
                    return 'nofavorit';
                }
            }
            return '';
        };
        
        $scope.FavoritesColumnModificar = function(id) {
        	TramNewFactory.getDossier(id).then(function(data){
        		var idDossierTransaction = 1;
        		var actualDossierTransaction = $linq(TramNewFactory.Dossier.dossierTransactions)
        			.where("x => x.actual == true").toArray();
        		if(actualDossierTransaction != null && actualDossierTransaction.lenght != null && actualDossierTransaction.lenght != 0){
        			idDossierTransaction = actualDossierTransaction[0].id;
        		} else {
        			idDossierTransaction = TramNewFactory.Dossier.dossierTransactions[0].id;
        		}
        		TramNewFactory.modifyFavorite(idDossierTransaction, $rootScope.LoggedUser.id).then(function(data){
                    $scope.tableDefinition.reloadInternalData(false,true);
                }).catch(function(error){
                    $scope.tableDefinition.reloadInternalData(false, true);
                });
            }).catch(function(error){
                $scope.tableDefinition.reloadInternalData(false, true);
            });
        }
        
        $scope.FavoritesColumn = new IconSearchColumn(
                'favoriteUsers',
                '<iconsearchcolumn definition="FavoritesColumn" filterModel="filterData" fieldName="favorite"/>',
                '5%',
                new IconSearchRender($scope, 'FavoritesColumn'),
                false,
                true,
                [
                    new IconSearchOption('favorit','favorit', 'fa-star text-warn text-lg', 'fa-star text-warn text-lg', 'global.literals.favorite'),
                    new IconSearchOption('nofavorit','nofavorit', 'fa-star-o text-lg', 'fa-star-o text-lg', 'global.literals.noFavorite'),
                ],
                'tableDefinition',
                $scope.FavoritesColumnRenderFunction,
                'FavoritesColumnModificar'
            );
        
        var DaysColumn = new RightAccessRemaingDaysColumn($filter, 'global.literals.days');
        var StatusColumn = new DossierStatusColumn($filter, 'global.literals.dossier');
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn);
        var TranCounterColumn = new TransactionsCounterColumn();
        var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
        var DossierTransactionActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','openDossier([data], [bigDossier])',undefined,false,undefined, undefined, ['bigDossier']),undefined,'id');
        var PrincipalColumn = new PrincipalThirdColumn($filter,'global.literals.principalDest');
        var CreatedDateColumn = new DateColumn($filter, 'global.literals.creation_date');
       	var dossierResponsibleColumn = new DossierResponsibleColumn($filter, 'global.tram.literals.dossierResponsible');
        var SubjectColumn = new DossierSubjectColumn($filter, 'global.literals.subject', $scope.languageColumn);

        var DossierTransactionStatusRender = function(data, type, full, meta) {
            var content = '';

            if(data) {
                content = '<i class="text-success fa fa-circle" title="' + $filter('translate')('global.literals.closedMS') + '" aria-hidden="true"></i> ';
            } else {
                content = '<i class="text-warn fa fa-circle" title="' + $filter('translate')('global.literals.openedMS') + '" aria-hidden="true"></i> ';
            }

            return content;
        }

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            return filterCall;
        }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.filterOrigin = 'dossierRightAccess';
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.favorite = $scope.filterData.favorite;
            if(filterCallAux.procedure) {
				filterCallAux.procedure = {id:filterCallAux.procedure.id};
			}
            if(filterCallAux.transactionType) {
				filterCallAux.transactionType = {id:filterCallAux.transactionType.id};
			}
            if(filterCallAux.procedureClassification) {
				filterCallAux.procedureClassification = {id:filterCallAux.procedureClassification.id};
			}
            return filterCallAux;
        }

        var columnsAux = [
        	{ id: 'favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender(), sortable: false},
            { id: 'dossierStatus', width: '50px', column: StatusColumn },
            { id: 'remainingDays', width: '40px', column: DaysColumn },
            { id: 'dossierNumber', width: '140px', title: $filter('translate')('global.literals.code') },
            { id: 'procedure.' + ProcedureColumn.getColumn(), width: '140px', title:ProcedureColumn.getTitle()},
            { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
            { id: 'subject', width: '150px', title: SubjectColumn.getTitle(),  render: SubjectColumn.getRender },
            { id: 'principalThird', width: '200px', column: PrincipalColumn, sortable: false },
            { id: 'responsibleUser.name', width: '110px', column: dossierResponsibleColumn},
            { id: 'creatorUser', width: '150px', column: CreatorUserColumn},
            { id: 'createdDate', width: '100px', column:  CreatedDateColumn},
            { id: 'actions', columnName: 'id', width: '40px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_view', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_view', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossier.dossierNumber', sort: [[7, 'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
            id: 'tableDossierRightAccess',
            origin: 'tram',
            objectType: 'Dossier',
            callOrigin: 'dossierRightAccess',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope,
			notAddExcel: true
        };
		$scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}

        $scope.openDossier = function (id, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	$state.transitionTo('annexa.tram.dossierRightAccess.viewdossier', ({ dossier: id }));	            
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		$state.transitionTo('annexa.tram.dossierRightAccess.viewdossier', ({ dossier: id }));
        	}
        }

        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }
    }])
    .controller('TramNewDossierController',['$scope', '$rootScope', '$state', '$stateParams', 'TramNewFactory', 'Language', 'HeaderService', 'AnnexaEntityFactory', function($scope, $rootScope, $state, $stateParams, TramNewFactory, Language, HeaderService, AnnexaEntityFactory) {
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.tram.newdossier') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#new-dossier','fa-floppy-o','global.literals.save',undefined,'saveEntity'),
                    new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#new-dossier',undefined,'global.literals.cancel',undefined,'cancelEntity')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.translations = { procedure: TramNewFactory.Procedure[Language.getActiveColumn()] };
        
        $scope.daysexpire = TramNewFactory.Procedure.maxDays;
        $scope.expiretime = TramNewFactory.maxDays;
        $scope.expiredate = TramNewFactory.expireDate;
        $scope.expireType = TramNewFactory.Procedure.expireType;

        $scope.entity = AnnexaEntityFactory.getNewTramForm($scope, TramNewFactory.Diligence);

    }])
	.controller('TramByNumberController',['$scope', 'TramNewFactory', '$state', '$stateParams', function ($scope, TramNewFactory, $state, $stateParams) {
		if(TramNewFactory.byNumberId && TramNewFactory.byNumberDtId && TramNewFactory.byNumberCanUpdate === true){
    		$state.go('annexa.tram.pending.viewdossier', {"dossier" : TramNewFactory.byNumberId, "dossierTransaction":TramNewFactory.byNumberDtId});
        }else if(TramNewFactory.byNumberId && TramNewFactory.byNumberRightAccess === false && TramNewFactory.byNumberCanUpdate === false){
			$state.go('annexa.tram.view.viewdossier', {"dossier" : TramNewFactory.byNumberId});
        }else if(TramNewFactory.byNumberId && TramNewFactory.byNumberCanUpdate === true){
			$state.go('annexa.tram.view.viewdossier', {"dossier" : TramNewFactory.byNumberId});
        }else if(TramNewFactory.byNumberId && TramNewFactory.byNumberRightAccess === true){
			$state.go('annexa.tram.dossierRightAccess.viewdossier', {"dossier" : TramNewFactory.byNumberId});
        }else{
        	$state.go('annexa.dashboard');
        }
    }])
	.controller('TramNewViewDossierController',['$scope', '$rootScope', 'HeaderService', '$state', 'TramNewFactory', 'AnnexaPermissionsFactory', '$stateParams', 'ABMShapesFactory', '$filter', function($scope, $rootScope, HeaderService, $state, TramNewFactory, AnnexaPermissionsFactory, $stateParams, ABMShapesFactory, $filter) {
        // region General
        $scope.dossier = angular.copy(TramNewFactory.Dossier);
		$scope.actualDossierTransactionId = $stateParams.dossierTransaction; 
        HeaderService.onChangeState($scope, function (message) {
			var isArchivedDossier = ((TramNewFactory.isArchivedDossier || ($scope.dossier && $scope.dossier.dossierStatus && _.contains(['CLOSED','TRANSFER','REJECTED','ARCHIVED'],$scope.dossier.dossierStatus)))?true:false); 
            if(!$rootScope.subHeadButtons){
            	$rootScope.subHeadButtons = [];
            }
			var addAudit = false;
    	    if (message.state.name == 'annexa.tram.pending.viewdossier' || message.state.name == 'annexa.tram.responsable.viewdossier' || message.state.name == 'annexa.tram.view.viewdossier') {
	        	if(!(!TramNewFactory.canUpdateDossier || ($scope.dossier && $scope.dossier.dossierStatus != 'IN_PROGRESS' && !$scope.isArchivedDossier) 
			         		|| (($scope.dossier && $scope.dossier.dossierStatus != 'TRANSFER' && $scope.dossier.dossierStatus != 'REJECTED' && !($scope.dossier.dossierStatus == 'ARCHIVED' && !$scope.dossier.archiveDefinitiveDate)) && $scope.isArchivedDossier))){
					if($scope.isTransactionFinal==true) {
                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-dossier-object', undefined, 'global.literals.finish', undefined, 'endDossier'));
                    }
                	var permissionsCancel = ['cancel_dossier'];
                    if(TramNewFactory.isResponsible($scope.dossier) || ($scope.dossier.creatorUser && $rootScope.LoggedUser && $scope.dossier.creatorUser.id == $rootScope.LoggedUser.id)){
                    	permissionsCancel.push('cancel_responsability_dossiers');
                    }
                    $rootScope.subHeadButtons.push(new HeadButton('btn text-white breadDivButton m-x-xs grey-500', undefined, '#edit-dossier-object', 'fa fa-minus-circle', 'global.literals.anular', undefined, 'cancelDossierModal').setPermissions(permissionsCancel));

                    if((TramNewFactory.isResponsible($scope.dossier) ||  $rootScope.esetMode || AnnexaPermissionsFactory.havePermission('create_dossier_transaction')) && $scope.dossier.procedure.guided == false){
                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs ', undefined, '#edit-dossier-object', undefined, 'global.literals.createTram', undefined, 'createTramResposibleModal'));
						$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs ', undefined, '#edit-dossier-object', undefined, 'global.literals.openSubprocedure', undefined, 'openSubprocedureModal'));
                    }
                    if($rootScope.app.configuration.show_dossier_foliate.value) { 
                    	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs',undefined,'#edit-dossier-object',undefined,'global.literals.foliate',undefined,'generateFoliate'));
                    }
                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-dossier-object', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditDossier').setPermissions('admin_audit'));
					addAudit = true;
                    if($rootScope.app.configuration.default_eni_values.show_dossier_eni){
                    	var permission = '';
                    	if($scope.dossier.responsibleUser.id == $scope.LoggedUser.id) {
                    		permission = 'process_dossier';
                    	} else {
                    		permission = 'generate_document_interoperable';
                    	}
                		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#edit-dossier-object', undefined, 'global.sec.literals.expEni', undefined, 'generateENIDossier').setPermissions(permission));
                	}
	           }else if($scope.dossier && $scope.dossier.closed) {
	                if(AnnexaPermissionsFactory.havePermission('reopen_dossier') || (!$rootScope.esetMode && TramNewFactory.isResponsible($scope.dossier))){
	                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#edit-dossier-object', 'fa-archive', 'global.literals.reopen', undefined, 'reopenTram'));
	                }
	                if($rootScope.app.configuration.show_dossier_foliate.value) {
	                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#edit-dossier-object',undefined,'global.literals.foliate',undefined,'generateFoliate'));
	                }
	            }
	            if(!addAudit){
					$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#edit-dossier-object', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditDossier').setPermissions('admin_audit'));
				}
			}
            $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-dossier-object', 'fa fa-exchange fa-rotate-90', '', undefined, 'expandAllBoxes').setTitle($filter('translate')('global.literals.expandContract')));
        });
		if($scope.dossier && $scope.dossier.dossierTransactions){
            var actualTransaction = undefined;
			if($scope.actualDossierTransactionId){
				actualTransaction = $linq($scope.dossier.dossierTransactions).firstOrDefault(undefined, "x => x.id == "+Number($scope.actualDossierTransactionId));	
			}
            if (actualTransaction && actualTransaction.actual) {
                $scope.actualDossierTransaction = actualTransaction;
            } else {
                actualTransaction = $linq($scope.dossier.dossierTransactions).firstOrDefault(undefined, "x => x.actual == true");
                if(actualTransaction){
                    $scope.actualDossierTransaction = actualTransaction;
                }else{
                    actualTransaction = $linq($scope.dossier.dossierTransactions).max("x => x.id");
                    if(actualTransaction){
                        $scope.actualDossierTransaction = actualTransaction;
                    }else{
                        $scope.actualDossierTransaction = undefined;
                    }
                }
            }
		}else{
			$scope.actualDossierTransaction = undefined;
		}
        
        $scope.isTransactionFinal = ($scope.actualDossierTransaction && ($scope.actualDossierTransaction.showEndProcess==true || ABMShapesFactory.isTransactionFinal($scope.actualDossierTransaction)==true));
        if($scope.actualDossierTransaction && $scope.dossier && $scope.dossier.procedure && $scope.dossier.procedure.guided == false){
            $scope.isTransactionFinal = false;
            var dossierTransactionIsFinal = false;
            if($scope.actualDossierTransaction && !$scope.actualDossierTransaction.subprocedure && $scope.actualDossierTransaction.transactionType && $scope.actualDossierTransaction.transactionType.finallyType){
                dossierTransactionIsFinal = true;
            } 
            var openTransactions = $linq($scope.dossier.dossierTransactions).where("x => x.actual == true").toArray();
            if(openTransactions && openTransactions.length == 1 && dossierTransactionIsFinal) {
                if(TramNewFactory.isResponsible($scope.dossier)){
                    $scope.isTransactionFinal = true;
                }
            }
        }

		HeaderService.changeState($state.current);
        //endregion
    }]);
